.MessageInfoMainContainer {
    width: 25%;
    border-right: 1px solid #d8f0f2;
    font-family: Poppins;
    /* overflow-y: auto; */
}

.MessageInfoHeaderContainer {
    height: 64px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}

.MessageInfoHeaderWrapper {
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MessageInfoHeaderContainer svg {
    width: 20px;
    cursor: pointer;
    margin-right: 20px;
}

.MessageInfoHeaderContainer span {
    color: #1296b0;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 15px;
}

.MessageInfoDetailsMainWrapper {
    height: 100%;
    overflow-y: auto;
    flex-grow: 1 !important;
}

.MessageInfoDetailsMainWrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.MessageInfoImageContainer {
    width: 100%;
    border-bottom: 1px solid #d8f0f2;
    padding-bottom: 20px;
}

.MessageInfoImageWrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0 5px 0;
}

.MessageInfoImageContainer .MessageInfoImageWrapper svg {
    mix-blend-mode: darken;
    position: relative;
    right: -60px;

}

.MessageInfoProfileImage {
    background: url("https://s3-alpha-sig.figma.com/img/a5e8/4f80/55ea064b73008932d5a1dbad2136c02c?Expires=1692576000&Signature=jOHieBfaLzX5~xvIIdAJP3Or-zIOuTmLrZM3B7WwuzlkFa9ZD~Mzrls0MZ3lQMQie17idUKOYntUfRGMRQc~6j9aaD70~NHL-uD3hda7dhqA7UH03wrqPR4-VNLON-8heqia2GfBvzldp3wI6EZLx4T1o5kruELlwtcKDhmMaskjBAxMRGZY-S9o1ncghozXjKJ6M7hvkp8pinQ2Tbx5L5BbGDiVZXoTp8qO2ojWz2nrZ1zpKV0ksVPy-4VJxkgOacMjx4vo62dN-I2Q7cTXl~VnVEpgXbHKYtmHqpGBk-~JeC7dU-3gw7TOBJlX75fweKOz6taSrwOSkDcJbH04AA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 102.879px;
    height: 102.879px;
    border-radius: 1000px;
    position: relative;
    top: 25px;
    left: -70px;
    /* z-index: -1; */

}

.imgText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.imgText h4 {
    font-size: 14px;
    font-weight: 500;
    color: #1296b0;
}

.imgText span {
    font-size: 11px;
    font-weight: 500;
}

.imgText .MessageInfoHrLine {
    width: 200px;
    margin: 5px 0;
}

.imgText .p {
    font-size: 12px;
    color: #faf6f6;
}

.MessageInfoAttachContainer {
    padding: 10px 40px;
    border-bottom: 1px solid #d8f0f2;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .media {
    color: #DE64A1;
    background-color: #FFEEFA;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .media span {
    padding-left: 10px;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .links {
    color: #1296B0;
    background-color: #D8F0F2;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .links span {
    padding-left: 10px;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .docs {
    color: #8A88ED;
    background-color: #F0F0FF;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
}

.MessageInfoAttachContainer .MessageInfoAttachWrapper .docs span {
    padding-left: 10px;
}

.MessageInfoMemberContainer {
    padding: 5px 30px 0px 30px;
    border-bottom: 1px solid #d8f0f2;
}

.MessageInfoMemberContainer p {
    padding-bottom: 7px;
}

.MessageInfoMemberContainer .MessageInfoMemberWrapper {
    padding: 10px;

}

.MessageInfoMemberContainer .MessageInfoMemberWrapper .creteNewGroup {
    display: flex;
    align-items: center;
}

.MessageInfoMemberContainer .MessageInfoMemberWrapper span {
    margin: 5px 0;
}

.creteNewGroup {
    background-color: rgba(216, 240, 242, 0.4);
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 20px;
}

.creteNewGroup p {
    color: #555;
    font-size: 15px;
    font-weight: 500;
    padding-left: 10px;
    padding-bottom: 0px !important;
}

.goupList {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.goupList .imageContainer {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.goupList .imageContainer img {
    width: 100%;
    height: 100%;
}

.goupList .textContainer h3 {
    color: #555;
    font-size: 14px;
}

.goupList .textContainer p {
    font-size: 11px;
    color: #555;
    padding-bottom: 0;
}

.MessageInfoExitContainer {
    padding: 30px 40px;
    padding-bottom: 100px;
}

.MessageInfoExitContainer .MessageInfoExitWrapper {
    display: flex;
    align-items: center;
}

.MessageInfoExitContainer .MessageInfoExitWrapper .MessageInfoExitText {
    font-size: 14px;
    color: #F71D1D;
    font-weight: 700;
    padding-left: 10px;
    margin-bottom: 4px;
}

.MessageInfoCameraIcon {
    position: relative;
    top: 100px;
    right: 159px;
    z-index: 10;
}

.MessageInfoAttachContainer .MessageInfoMuteNotifications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;
    padding: 10px 0px 10px 15px;
    border-radius: 5.5px;
    margin: 10px 0;
    font-size: 13px;
    font-weight: 600;
}