.multiStepFormHeader {
  height: 120px;
  flex-shrink: 0;
  display: flex;
  padding: 24px 0 24px 0;
  justify-content: space-around;
  border-bottom: 1px solid var(--grey);
}

.multiStepFormBody {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 167px);
}

.multiStepFormBodyContainer {
  width: 100%;
  max-height: calc(100% - 55px);
  overflow: auto;
  display: grid;
}

.multiStepFormButtonContainer {
  gap: 12px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 12px;
  position: absolute;
  bottom: 0;
  background-color: var(--form-background);
  flex-shrink: 0;
  border-style: solid;
  padding-left: 50px;
  padding-right: 50px;
  border-width: 0px;
  border-top-width: 2px;
  border-color: rgba(230, 230, 230, 1);
}

.activeStep {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--primary-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
}

.completedStep {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: white;
  background-color: var(--primary);
}

.inActiveStep {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #d9d9d9;
  color: white;
}

.headerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  z-index: 1;
}

.completedStepContainer {
  color: black;
}

.completedStep>svg {
  scale: 1.2;
}

.activeStepContainer {
  color: black;
}

.multiStepHeaderTile {
  flex: 1 1 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inActiveStepContainer {
  color: var(--secondary-text);
}

.connectLine {
  display: block;
  border-color: rgb(189, 189, 189);
  border-top-style: solid;
  border-top-width: 1px;
  position: absolute;
  width: 100%;
  margin-bottom: 26px;
}

.connectLineFirstStep {
  width: 50% !important;
  right: 0 !important;
}

.connectLineLastStep {
  width: 50% !important;
  left: 0 !important;
}