.blockListsContainer{
    width: 100%;
    
    height: calc(100% - 64px);

    border-right: 1px solid #d8f0f2;
}
.blockListsContainer .blockListHeaderContainer{
    height: 64px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}
.blockListsContainer .blockListHeaderContainer .blockListHeaderWrapper{
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
}
.blockListsContainer .blockListHeaderContainer svg {
    width: 20px;
    cursor: pointer;
}
.blockListsContainer .blockListHeaderContainer span {
    color: #1296b0;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding-left: 15px;
}
.blockListsContainer .blockListsWrapper .chatListContainer{
    padding: 10px 30px;
    width:100%; 
    overflow-y:scroll; 
    position:relative;
    height: 100%;
}
.blockListsContainer .blockListsWrapper .chatListContainer .chatListWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:  5px 0;
}
.blockListsContainer .blockListsWrapper .chatListContainer .chatListWrapper .chatListLeftcontent{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.blockListsContainer .blockListsWrapper .chatListContainer .chatListWrapper .chatListLeftcontent .activeImage{
    margin-left: 0 !important;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    margin: 12px;
    position: relative;
}
.blockListsContainer .blockListsWrapper .chatListContainer .chatListWrapper .chatListRightContent .unblockIcon svg{
    width: 30px;
    height: 30px;
}
.react-confirm-alert .react-confirm-alert-body > h4{
    font-size: 15px !important;
    font-weight: 500;

}
.createComm{
    margin-top: 30px;
     font-size: 16px;
     color: #555555;
     font-weight: 500;
}
.createCommbody{
    margin-top: 12px;
     font-size: 11px;
     color: #A1A1A1;
     text-align: center;
}