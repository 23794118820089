.sbActivityContainer {
    /* position: absolute;
    top: 0;
    right: 0; */
    z-index: 10;
  }
.sbActivityContainer span{
    height: 37px;
    width: 37px;
    padding: 10px;
  }
.sbActivityContainer span:hover{
    background: var(--primary-hover);
    border-radius: 100px;
  }
  .sbActivityHolder {
    border: none;
    border-radius: 6px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    top: 55px;
  }
  .sbActivityHolder {
    display: flex;
    flex-direction: column;
  }
  .sbActivityHolder .headingFilterHeader h5 {
    width: 150px;
    font-size: 13px;
    color: rgb(95, 95, 95);
  }
  .sbActivityHolder .headingFilterHeader {
    cursor: pointer;
  }
  .sbActivityHolder .headingFilterHeader:hover {
    background-color: rgba(216, 240, 242, 0.4);
  }
  
  