.quickAccessImgContainer{
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
    border-radius: 6px;
    width: 92px;
    height: 92px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quickAccessContainer{
    overflow-y: auto;
    display: flex;
    flex-flow: row wrap;
    height: -webkit-fill-available;
    margin-top: 8px;
    justify-content: center;
    gap: 37px;
}
.quickAccessSubContainer{
    display: flex;
    justify-content: space-evenly;
    margin-top: 14px;
}
/* .quickAccessImg{
    height: 69%;
    width: 75%;
    position: relative;
    left: 15px;
    top: 13px;
} */
.quickAccessImgText{
    position: absolute;
    font-size: 13px;
    width: 112px;
    bottom: 0;
    /* left: 10px; */
    top: 95px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: center;

/* PeopleSol Dark Grey */

    color: #555555;
}