
.OrgsActionsHolder{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
/* .approvalActionsHolder > img{
    width: 40%;
} */
.OrgActionsHolder{
    color: #D8F0F2;
    background: linear-gradient(180deg, #1296B0 0%, #117F95 100%);
    /* box-shadow: 0px 2px 6px rgb(18 150 176 / 30%); */
    border-radius: 4px;
    width: 68%;
    min-width: 71px;
    padding: 3px;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
}
.OrgActionsText{
    text-align: center;
}
.TextAction{
    padding-left: 18px
}