.radiobuttonCSS {
  padding: 15px 10px;
  background: white;
  display: flex !important;
  align-items: flex-start;
  color: #aaaaaa;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.radioCust.rewardscustom .label-custom {
  font-weight: 500;
  text-align: center;
  padding: 0px 9px;
}
.rewardscustom p {
  font-size: 12px !important;
  font-weight: 200 !important;
}
.module-reward .box {
  width: 45%;
}

.radiobuttonCSS input {
  margin-top: 16px;
}

.createHead label {
  font-weight: 600;
}
.popsC {
  padding: 0 !important;
}

.radioCust.rewardscustom {
  padding: 7%;
}

.CancelButtoncss {
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  color: #1190a9;
}

.GrayButtoncss {
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #bebebe;
  color: #fff !important;
}

.proplable-circel {
  border: 1px solid gray;
  padding: 4px 10px;
  color: gray;
  border-radius: 50px;
}

.proplable {
  color: #ffff;
  border: none;
  padding: 4px 10px;
  border-radius: 50px;
  background-color: #1190a9 !important;
}
.PopradioButton {
  /* background-color: #eff9fa !important; */
  /* background-image: linear-gradient(
    to left top,
    #ade0e97a,
    #b9e2f14a,
    #bde4fe69,
    #d8eaff00,
    #edf2ff00,
    #edf2ff00,
    #edf2ff00,
    #edf2ff00,
    #d8eaff00,
    #bde4fe6e,
    #9ddff852,
    #7adaec3b
  ); */
  background: url("../../../Assets/reward-bg.svg") no-repeat;
  background-size: cover;
}
.radiobuttonCSS input {
  display: none;
}

.primarycolors {
  background-color: rgb(18, 150, 176);
  color: #fff;
}
.label-custom.proplable p {
  color: #555555 !important;
}

.popup-box {
  backdrop-filter: blur(10px);
}
.P-20 {
  padding: 20px 35px;
}
.P-40 {
  padding: 20px 60px;
}

.input-longtext {
  height: 100px;
}

.Reward-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 25px;
  margin-top: 7%;
  background: #fff4e5;
}

.Reward-alert-change {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 25px;
  background: #fff4e5;
}
.radioFormikcustomflex {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.pb-30 {
  padding-bottom: 30px;
}

.CheckFormik-Checkbox {
  display: flex;
  justify-content: left;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 35px;
}
.w-100 {
  width: 100%;
}
/* 
.formikComponentContainerPmsRewards */
.radioreward {
  padding: 5px;
  background-repeat: no-repeat;
  width: 115px;
  height: 115px;
  border-radius: 10px;
  text-align: center;
  align-items: flex-start;
  margin: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 4px solid #fff;
}

.winner .formcustom {
  gap: 80px !important;
}

.winner h5,
.qualitativeset h5,
.setawards h5 {
  color: #1296b0;
  padding-bottom: 8px;
  font-size: 15px;
}

.formcustom-employee .officeLocationEmployeeList {
  display: flex;
  justify-content: center;
  padding: 16px 0px;
  margin-inline: 52px;
  border-top: 1px solid #bfbfbf;
}

.formcustom-employee {
  display: grid;
  grid-template-columns: 100%;
}

.rewards-filter-name {
  font-size: 14px;
  font-weight: 500;
  color: #1296B0;
  padding: 25px 0px 6px;
  margin-inline: 52px;
}

.formcustom-employee .selectAllCheckbox {
  display: flex;
  justify-content: space-between;
  width: 93%;
}

.formcustom-employee h4,
.formcustom-employee h6 {
  color: #1190a9;
}

.formcustom-employee
  .officeLocationEmployeeList
  .availableEmployeeList
  .availableEmpBody
  .availableEmpHeader,
.formcustom-employee
  .officeLocationEmployeeList
  .selectedEmplList
  .selectedEmpBody
  .selectedEmpHeader {
  display: flex !important;
  justify-content: space-between !important;
}

.formcustom-employee .availableEmpBody,
.formcustom-employee .selectedEmpBody {
  /* margin: 0px 45px; */
  max-width: 270px;
  background: #ffff;
}

.formcustom-employee .availableEmpHeader {
  padding: 13px;
}

.searchinputbox {
  width: 100%;
  padding: 10px;
  margin: 17px 6px;
  border: 1px solid #e5dede;
}

.availableEmpBody ul {
  border-top: 0.665px solid var(--Grey-2, #e6e6e6);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.button-flex-align {
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 5px;
  background-color: #f71d1d;
  padding: 6px 22px;
  margin: 15px auto 10px;
  width: fit-content;
  color: #fff;
  cursor: pointer;
}
.formcustom-employee
  .officeLocationEmployeeList
  .availableEmployeeList
  .availableEmpBody
  ul
  li,
.formcustom-employee.officeLocationEmployeeList
  .selectedEmplList
  .selectedEmpBody
  ul
  li {
  justify-content: space-between;
}

.no-employees {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restorebuttone svg {
  color: #fff !important;
}
.formcustom-employee .availableEmpBody button,
.restorebutton {
  margin: 10px;
}

.formcustom-employee .availableEmployeeList button:disabled {
  background-color: #999;
}

.formcustom-employee .availableEmployeeList button {
  padding: 6px 22px;
  background-color: #f71d1d;
}
.accordions-headers {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.winndercategory-bg {
  background-color: #f1f9fb;
  border-radius: 12px;
  padding: 2%;
  margin: 15px;
}
.accordions {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin: 5%;
}

.winndercategory-bg .formcustom {
  column-gap: 13%;
  display: grid;
  justify-content: space-between;
}
.winndercategory-bg .input-longtext {
  height: 80px !important;
  margin: 0px;
  padding: 0px;
}

.accordions-body .input-shorttext,
.accordions-body .input-longtext,
.accordions-body .react-select-container {
  border: 1px solid #e6e6e6 !important;
}
.text-center {
  text-align: center;
}

.accordions-body {
  margin: 15px;
}

.buttonaddaward {
  display: flex;
  justify-content: end;
  margin: 10px 20px;
}

.accordions-headers h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 20px;
}

.formcustomthree.setreward {
  gap: 8%;
}
.formcustomthree.setreward .input-longtext {
  height: 142px;
}

input[type="radio"]:checked .radioreward {
  border: 3px solid #1190a9;
  border-radius: 10px;
}


/*---------------- rewards publish winner -------------------*/

.publish_winner_container {
  height: 500px !important;
}
.publish_winner_container td {
  font-family: "Poppins" !important;
  font-size: 10px !important;
}
.publish_winner_container .thead { 
    font-weight: 500;
    background-color: #F9F9F9;
    position: sticky;
    top: 6px;
    z-index: 5; 
}
.publish_winner_container .optionsCell { 
  left: 0;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  padding: 12px 0px 12px 0px !important;
}
.publish_winner_container .actionsCellCheckInactive { 
  left: 29px;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  box-shadow:  none;
}
.publish_winner_container .actionsRowCheckInactive { 
  left: 29px;
  position: sticky;
  z-index: 1;
  background-color: var(--table-back);
  box-shadow: none;
  padding: 12px 6px !important;
}

.publish_winner_container .table > tbody > tr > td {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border-bottom: 1px dashed #E6E6E6;

}
.publish_winner_container .table > thead > tr > td:first-child > button { 
  visibility: hidden;
}
.publish_winner_container .table > tbody > tr > td:nth-child(2) { 
  font-size: 14px !important;
}
.publish_winner_container .table > tbody > tr > td:nth-child(3) { 
  text-align: left;
}

/*----------- here start publish winner department container ---------------*/

.publish_winner_department_container {
  height: 500px !important;
}
.publish_winner_department_container .moreActionContainer,
.publish_winner_container .moreActionContainer
  {
  left:46px;
}
.publish_winner_department_container .department_table {
  white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0px 0px;
    position: relative;
    table-layout: fixed;
    flex-grow: 1;
    display: block;
    overflow: auto;
}
.publish_winner_department_container td {
  font-family: "Poppins" !important;
  font-size: 10px !important;
  padding: 10px;
}
.publish_winner_department_container .thead { 
    font-weight: 500;
    background-color: #F9F9F9;
    position: sticky;
    top: 6px;
    z-index: 5; 
}
.publish_winner_department_container .optionsCell { 
  left: 0;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  padding: 12px 0px 12px 0px !important;
}
.publish_winner_department_container .actionsCellCheckInactive { 
  left: 29px;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  box-shadow:  none;
  box-shadow: 3px 0px 0px -1px #f9f9f9;
}
.publish_winner_department_container .actionsRowCheckInactive { 
  left: 29px;
  position: sticky;
  z-index: 4;
  background-color: var(--table-back);
  box-shadow: 3px 0px 0px -1px #fff;
  padding: 12px 6px !important;
}

.publish_winner_department_container .department_table > tbody > tr > td {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border-bottom: 1px dashed #E6E6E6;

}
.publish_winner_department_container .department_table > thead > tr > td:first-child > button { 
  visibility: hidden;
}
.publish_winner_department_container .department_table > thead > tr > td:nth-child(3) { 
  text-align: left;
  left: 94px;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  /* border-right: 1px solid #e6e6e6; */
}
.publish_winner_department_container .department_table > thead > tr > td:nth-child(4) { 
  text-align: left;
  left: 230px;
  position: sticky;
  z-index: 4;
  background-color: #F9F9F9;
  border-right: 1px solid #e6e6e6;
}
.publish_winner_department_container .department_table > tbody > tr > td:nth-child(2) { 
  font-size: 14px !important;
}
.publish_winner_department_container .department_table > tbody > tr > td:nth-child(3) { 
  text-align: left;
  left: 94px;
  position: sticky;
  z-index: 1;

}
.publish_winner_department_container .department_table > tbody > tr > td:nth-child(4) { 
  text-align: left;
  left: 230px;
  position: sticky;
  z-index: 1;
  border-right: 1px solid #e6e6e6;

}

.publishwinnertabletoglebutton {
  position: relative;
  bottom: 0px;
  background-color: #E9F3F4;
  padding: 8px 10px;
  border-radius: 6px;
  width: fit-content;
}

.publishRewardSelectCustom .inputSelect{
  background: #EDF8F9;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important;
  border-radius: 4px !important;
  width: calc(100% - 24px) !important;
  margin: 12px;
  padding: 12px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  outline: 0;
  min-height: 40px;
  min-width: 172px;
  flex: 1 1;
}

.publishWinnerCrossimg{
  position: absolute;
  top: 25px;
  right: 25px;
}

.rewardspublishwinnerfooter{
  margin-top: 10px;
  display: flex;
  border-top: 1px solid #e6e6e6;
  justify-content: flex-end;
  background-color: #fff;
  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  height: 65px;
  align-items: center;
}

.publishwinner .tableHeaderContainer {
  z-index: 5;
  background-color: var(--white);
  padding: 0 32px 0 12px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  margin-top: 3px;
  min-height: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
 
}

/* rewardsposition pms styling start here (All Rewards)*/

/* Tabs container */
.tab-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #E9F3F4;
  border-radius: 8px;
  padding: 8px;
  width: fit-content;
  margin: 0 0;
}

/* Tab buttons */
.rewardstab {
  flex: 1;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins" ;
  color: #555555;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

/* Active tab styling */
.tabactive {
  background-color: #1296B0;
  color: #fff;
}

/* card section start here pms rewards module*/

.rewardcard-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  padding: 24px 183px 24px 16px;
  margin: 14px;
  width: calc(50% - 28px);
}

.rewardcard-content {
  flex: 1;
  margin-right: 16px;
}

.rewardcard-title {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  color: #FB7158;
  margin: 0 0 8px;
}

.rewardcard-description {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  color: #555;
  margin: 0 0 16px;
}

.rewardcard-button {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  color: #FB7158;
  background-color: #FFF2F0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.rewardcard-button:hover {
  /* background-color: #d64c4c; */
  cursor: pointer;
}

.rewardcard-button .arrow {
  font-size: 12px;
  margin-left: 6px;
}

.rewardcard-image img {
  /* width: 150px;
  height: auto;
  border-radius: 8px; */
  position: absolute;
  bottom: 0px;
  right: 10px;
}


/* winner popup section start here (rewards submodule in pms) */

.pmsrewardswinnercontainer{
    height: 0;
    position: absolute;
    background-color: #faf9f8;
    right: 0;
    z-index: 9;
    box-shadow: -5px 6px 15px rgb(0 0 0 / 5%);
    top: 0px;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: 0.5s;
    overflow: hidden;
    width: 0;
    display: flex;
    flex-direction: column;

}
.pmsrewardswinnercontainer{
    height: 0;
    position: absolute;
    background-color: #fff;
    right: -9px;
    z-index: 9;
    /* box-shadow: -5px 6px 15px rgb(0 0 0 / 5%); */
    /* box-shadow: -2px 2px 20px 0px rgba(0, 0, 0, 1); */
    box-shadow: -2px 2px 20px 0px #0000001F;
    top: 0px;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: 0.5s;
    overflow: hidden;
    width: 0;
    display: flex;
    flex-direction: column;

}
.pmsrewardswinnercontainerActive{
    height: 97%;
    /* border: 1px solid #1296b038; */
    overflow: auto;
    overflow-x: hidden;
    width: 40%;
    margin: 20px;
}

/* General Styling */
.result-card {
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  max-width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Header */
.header {
  position: sticky;
  top: 0px;
  z-index: 1;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      gap: 6px;
      background: #ffffff;
      box-shadow: 0px 1px 6px rgb(0 0 0 / 5%);
      border-radius: 6px 6px 0px 0px;
      justify-content: space-between;
}
 .header .rewarstitle {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
  color: #555;
 }
.back-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.search-bar {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.loverFoter{
  position: sticky;
  top: 50px;
  background: #fff;
  padding-top: 14px;
  z-index: 1;
}
/* Tabs */
.tabswinnerlist {
 
  display: flex;
  margin-bottom: 16px;
  margin: 0px 38px 0px 38px;
  padding: 11px 12px;
  border-radius: 12px;
  border: 1px solid #E6E6E6;
}

.tabwinnerlinst {
  flex: 1;
  padding: 5px 23px;
  text-align: center;
  border-radius: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  color: #555;

}

.tabwinnerlinst.active {
  background: #E9F3F4;
  color: #1296B0;
  border-radius: 6px;
}

.select-section{
  margin: 0px 26px 0px 38px;
  /* width: 100%; */
  /* margin-inline: 38px; */
  display: flex;
  justify-content: end;
}

/* Leaderboard */
.leaderboard {
  display: flex;
  margin: 0px 38px 24px 38px;
  border-radius: 15px;
  border: 1px solid #E6E6E6;
  background-image: url('../../../Assets/rewardswinnerbackgroundimage.png'); /* Path to the image */
  background-size: cover; /* Ensures the image covers the container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  min-height: 342px;
  /* justify-content: space-around; */
  flex-direction: column;
}

.leader {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.leaderItem{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
.winner{
  display: flex;
  align-items: center ;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 27px;

}

.winnersouterdiv1 {
  position: relative; 
  background-image: url('../../../Assets/bgfirstposition.png'); 
  background-size: contain; 
  background-position: top center; 
  background-repeat: no-repeat;
  width: 80px; 
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px;  */
}
.winnersouterdiv1_img{
  position: absolute;
  margin-inline: auto;
  top: 27px;
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  border: 2px solid #fff; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}
.winnersouterdiv2 {
  position: relative; 
  background-image: url('../../../Assets/bgsecondposition.png'); 
  background-size: contain; 
  background-position: top center; 
  background-repeat: no-repeat;
  width: 80px; 
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px;  */
}
.winnersouterdiv2_img{
  position: absolute;
  margin-inline: auto;
  top: 10px;
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  border: 2px solid #fff; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}
.winnersouterdiv3 {
  position: relative; 
  background-image: url('../../../Assets/bgthirdposition.png'); 
  background-size: contain; 
  background-position: top center; 
  background-repeat: no-repeat;
  width: 80px; 
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px;  */
}
.winnersouterdiv3_img{
  position: absolute;
  margin-inline: auto;
  top: 10px;
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  border: 2px solid #fff; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}
.winner > h6{
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  color: #555;
}
.winner > p{
  font-size: 11px;
  font-weight: 400;
  font-family: "Poppins";
  color: #bfbfbf;
}

.leftposition{
  padding: 20px;
}
.leftposition ul{
  list-style: none;
  padding: 0;
}
.leftposition li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;;
}

.winnerconsolation{
   margin: 0px 38px 24px 38px;
   padding: 20px;
   border-radius: 15px;
   border: 1px solid #E6E6E6;
   /* max-height: 300px;
   overflow: auto; */
}

.winnerconsolation_h6 {
  font-size: 11px;
  font-weight: 400;
  font-family: "Poppins";
  color: #555;
  margin: 0px 38px 10px 38px;
}


.winnerconsolation ul {
  list-style: none;
  padding: 0;
  /* overflow: auto; */
}

.winnerconsolation li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.liItems{
   display: flex;
   align-items: center;
    gap: 10px;
    font-size: 13px;
    font-weight: 500;
    font-family: "Poppins";
    color: #555;
  
}

.liItems img{
   width: 50px;
   height: 50px;
   border-radius: 50%;
  
}

.winnertag {
  background: #FFF2F0;
  color: #FB7158;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  font-family: "Poppins";

}

.tag {
  background: #F0F0FF;
  color: #8A88ED;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  font-family: "Poppins";

}

.inputSelectreward{
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02) !important;
    border-radius: 4px !important;
    width: calc(100% - 24px) !important;
    margin: 12px 12px 12px 12px;
    padding: 12px;
    border: 1px solid #e6e6e6 !important;
    box-sizing: border-box;
    outline: 0;
    min-height: 40px;

}
