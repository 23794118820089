@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins";
    font-style: normal;
}

/* 
.Appraisal_container {
  scale: 0.8;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 2000px;
  margin: 0 auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.08);
} */




.content {
    margin-top: 100px;
    /* Adjust based on header height */
    margin-bottom: 100px;
    /* Adjust based on footer height */
}

.Appraisal_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    position: relative;

}

.Appraisal_heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    /* padding: 40px 40px 25px; */
    padding: 0px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 114px;


}

.Appraisal_footer_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

}

.Appraisal_container_border {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 1020px;
}



.Appraisal_container_content {
    /* padding: 40px 40px 25px; */
}



.Appraisal_footer {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px 25px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.Appraisal_container_content h1 {
    font-size: 20px;
    text-align: center;
}

.Appraisal_container_content h2 {
    font-size: 28px;
    margin: 20px 0;
}

.Appraisal_container_content h3 {
    font-size: 15px;
    margin: 8px 0;
    text-align: center;
    font-weight: 400;
}

.Appraisal_container_content p {
    font-size: 11px;
    margin: 10px 0;
    line-height: 1.6;
}

.Appraisal_container_content h5 {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.Appraisal_footer_container p {
    font-size: 11px;
}

.Appraisal_container_content h4 {
    font-size: 20px;
    color: #194f76;
    margin: 20px 10px;
}

.Appraisal_container_content h6 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
}

.Appraisal_container_content ul {
    margin-left: 46px;
    font-size: 18px;
    line-height: 1.7;
}

.Appraisal_container_content table {
    width: 100%;
    border-collapse: collapse;
    font-size: 11px;
    text-align: center;
    font-weight: 500;
}

.Appraisal_container_content th,
.Appraisal_container_content td {
    padding: 10px;
}

.Appraisal_container_content thead th {
    color: #194f78;
    font-size: 13px;
    font-weight: 600;
}

.Appraisal_container_content td {
    font-size: 11px !important;
}

.appr1 tbody tr:nth-child(odd) {
    background-color: #d6e4ea;
}

/* .appr1 tbody tr:hover {
    background-color: #0d324f;
    color: #fff;
} */

.Appraisal_container_content .table-container {
    overflow: hidden;
    border-radius: 8px;
    margin: 20px 0;
    border: 3px solid #194f76;
    border-radius: 40px;
}

/* .Appraisal_container_content tbody tr .selected {
    background-color: #0d324f;
    color: #fff;
} */