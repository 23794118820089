.companyContentHolder{
    height: 100% !important;
    padding:0 ;
}

.companyCard{
    height: 100% !important;
    width: 100% !important;
}
.companyCardContainer{
    /* box-shadow: 7px 5px 7px rgb(0 0 0 / 17%); */
    box-shadow: none;
}
.companyDetailContainer{
    width: 33%;
    height: 100px;
    justify-content: space-evenly;
    min-width: 320px;
}
.companyDetailContainer > .verticalLine{
    width: 2px;
}
.companyHeadText{
    font-weight: 500;
    font-size: 16px;
}
.companyDetailContainer > label{
    font-size: 16px;
}
.companyContentBackground{
    right: -43px;
}
.companyDetailsContainerHolder{
    height: 100%;
    justify-content: space-around;
    overflow: auto;
    padding: 12px;
}
.editActive{
    background-color: var(--form-background) !important;
}
.pencilBackground{
    background-color: var(--primary-hover) !important;
}
.updateButton{
    position: absolute;
    bottom: 60px;
    right: 40px;
}
.cancelButton{
    position: absolute;
    bottom: 60px;
    right: 170px;
}
.noDatastyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 31px;
    margin: auto;
}