.inputPdfContainer {
  border: 1px solid rgb(202 202 202 / 15%);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.inputPdfContainer .inputPdfWrapper .react-pdf__Document {
  overflow: hidden;
}
.leftPdfArrow {
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: fit-content;
  cursor: pointer;
  width: 35px;
}
.rightPdfArrow {
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 35px;
  cursor: pointer;
}
.inputPdfBottomContainer {
  visibility: hidden;
  transition: all 0.5s;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 4px 4px;
  color: #fff;
  background: #000;
  backdrop-filter: blur(25px);
  overflow: hidden;
  opacity: 0;
}
.pdfLine {
  margin-left: 5px;
  width: 93%;
  border-top: 2px solid #fff;
}
.inputPdfContainer:hover .inputPdfBottomContainer {
  visibility: visible;
  opacity: 0.4;
}
.inputPdfWrapper .react-pdf__Page__canvas {
  margin: auto;
  width: 100% !important;
  height: 700px !important;
}
