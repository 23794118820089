.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  z-index: 999;
}

.box {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  min-width: 400px;
  height: fit-content;
  max-height: 70vh;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 80%;
  border-radius: 6px;
  background: #faf9f8;
  min-height: 280px;
  max-width: 82%;
}

.createHead {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 6px;
}

.createHead>label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #1296b0;
}

.createHeadvideowarning {
  background-color: white;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 6px;
}

.createHeadvideowarning>label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: red;
}

.close-icon {
  cursor: pointer;
  height: 17px;
}

.popApproveLabel {
  display: flex;
  flex-direction: column;
  padding: 8px 22px;
}

.newPad {
  padding: 0 !important;
}

.popupContentHolder {
  padding: 12px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.rexSty {
  padding: 0;
}

.contentClassName {
  justify-content: flex-start;
}

.smallHeightPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.success-pop-container {
  padding: 12px 24px 24px 24px;
  font-size: 15px;
  background-color: white;
}

.status-info {
  margin-bottom: 15px;
}

.data-info {
  margin-top: 15px;
}

.data-dat {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginCred {
  padding-bottom: 6px;
  margin-top: 40px;
  border-bottom: 2px solid var(--primary-hover);
}