.quill {
    padding:12px
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
    background: white;
    height: 120px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    border: 1px solid transparent;
    box-sizing: border-box;
    outline: 0;
    margin-top:4px
}
.ql-snow .ql-tooltip{
    left: -1px !important;
    top: -14px !important;
    border: 1px solid #5bc4bf6e;
}