.userAttachments{
  width: 25%;
}
.userAttachments .userAttachmentsContainer{
  width: 100%;
}

.userAttachmentHeaderContainer{
  height: 64px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}
.userAttachmentHeaderWrapper {
    padding-left: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .userAttachmentActions{
    padding: 15px 10px;
    border-bottom: 1px solid #daeef1;
  }
  .userAttachmentActions .userAttachmentActionsWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .userAttachmentActionsWrapper div{
    margin: 0 10px;
  }
  .userAttachmentActions .userAttachmentActionsWrapper .media{
    color: #DE64A1;
    background-color:#FFEEFA ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .userAttachmentActions .userAttachmentActionsWrapper .media span{
    padding-left: 10px;
  }
  
  .userAttachmentActions .userAttachmentActionsWrapper .links{
    color: #1296B0;
    background-color:#D8F0F2 ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .userAttachmentActions .userAttachmentActionsWrapper .links span{
    padding-left: 10px;
  }
  
  .userAttachmentActions .userAttachmentActionsWrapper .docs{
    color: #8A88ED;
    background-color:#F0F0FF ;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .userAttachmentActions .userAttachmentActionsWrapper .docs span{
    padding-left: 10px;
  }
  