.dataContainerDashboardCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  overflow-y: auto;
}
.dataContainerDashboardCard::-webkit-scrollbar {
  width: 3px;
  scroll-behavior: smooth
}

.attendanceDateTimeHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timerTodayTime {
  font-weight: 500;
  font-size: 40px;
  color: var(--primary);
  margin-top: 15px;
}
.dateTodayTime {
  font-weight: 400;
  font-size: 16px;
  color: var(--secondary-text);
}
.attendanceButton { 
  background: var(--notification-red);
  border-radius: 3.31953px;
  color: var(--white);
  width: 69%;
  gap: 12px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  gap: -1px;
  padding: 14px 14px;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}
.attendanceButton:hover {
  cursor: pointer;
}
.clockedIn {
  background-color: var(--notification-green);
}
.clockedIn > svg > g > #Line113 {
  transform: rotate(180deg) translateX(-80%) translateY(-100%);
}

.teamAttendanceTableHeader > th {
  font-weight: 400;
  font-size: 13px;
  padding: 6px;
  text-align: left;
}

.teamAttendanceCard {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  width: 100%;
  border-spacing: 0 1em;
  /* width: 97%; */
}
.teamAttendanceProfileItem {
  min-width: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.teamAttendanceProfileItem > img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.teamAttendanceCard > td,
.teamAttendanceCard > th {
  text-align: start;
}
.teamAttendanceNameItem {
  word-wrap: break-word;
  width: 10rem;
}
.teamAttendanceItem {
  min-width: 75px;
}
.teamAttendanceItemContainer {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  font-size: 12px;
}
.shift-progress-line {
  width: 100%;
  height: 2px;
  background-color: #e6e6e6;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.end-dot {
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: -1;
  background-color: #e6e6e6;
  border-radius: 50%;
  right: 0;
}
.start-dot {
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: -1;
  background-color: #e6e6e6;
  border-radius: 50%;
  left: 0;
}
.shift-progress-line-container {
  width: 100%;
  height: 46px;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 2px;
  width: 100%;
  background-color: var(--primary);
  transition: width 1s ease-in-out;
  position: absolute;
}
.progress-bar-container {
  height: 100%;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
}
