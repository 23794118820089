.boxContentHolder{
    display: flex;
    z-index: 1;
    width: 100%;
}
.boxTextHolder{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    width: 100%;
}
.boxTextHolderHeading{
    font-weight: 400;
    font-size: 16px;
}
.boxTextHolderText{
    font-weight: 500;
    margin-top: 1rem;
    font-size: 18px;
}
.boxSeparator{
    height: 5rem;
    background-color: var(--grey);
    width: 1px;
    margin: 0 1rem; 
}
.last .boxSeparator{
    display: none;
}