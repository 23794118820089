.tabDeHead{

  z-index: 5;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  background-color: var(--white);
  padding: 0px 0px 0 12px;
  display: flex;
  align-items: center;
  margin: 0 0 0px 24px;
  min-height: 50px;
  height: 50px;
}
.tableHeaderContainer {
  z-index: 5;
  background-color: var(--white);
  padding: 0 32px 0 12px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  margin-top: 3px;
  min-height: 60px;
  height: 60px;
}
.tableHeaderContainer > label {
  color: var(--primary);
  font-weight: 500;
  margin-right: auto;
}
.tableHeaderContainer > .chatListSearchContainer > .chatListSearch {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
}
.tableHeaderContainer > .chatListSearchContainer > .chatListSearch:focus {
  border-bottom: 2px solid var(--primary);
  outline: none;
}
.tableFooterContainer {
  height: 45px;
  min-height: 45px;
  z-index: 0;
  background-color: white;
  display: flex;
  align-items: center;
  margin: 0 12px;
  color: var(--secondary-text);
  gap: 6px;
  flex-shrink: 0;
}
.tableFooterContainer > span {
  color: var(--primary);
  margin-right: auto;
}
.recordDropdown {
  color: black;
}
