.noRequests {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
}
.noRequestsText {
  color: var(--secondary-text);
  margin-left: auto;
  width: fit-content;
  margin-right: auto;
  margin-top: 6px;
}
