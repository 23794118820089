/* .skillContainer {
    width: 90%;
    margin: 1rem;
    align-self: center;
    justify-self: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
    background-image: url("../../../Assets/RewardsBackground2.svg");
    background-size: 278px;
    background-repeat: no-repeat;
    display: flex;} */

.skillContainer {
    width: 90%;
    margin: 1rem;
    /* align-self: center; */
    /* justify-self: center; */
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
    border-radius: 6px;
    background-image: url(http://localhost:3000/static/media/RewardsBackground2.a944c7fdf515cdad478c.svg);
    background-size: 278px;
    background-repeat: no-repeat;
    display: flex;
    /* height: 700px; */
    /* align-content: flex-start; */
    align-items: flex-start;
}


.subSkillContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.skillHeadingContainer {
    color: var(--primary);
    margin-top: 2rem;
    font-weight: 500;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
}

.skillHeadingContainer>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.skillHeadingContainer>div>img {
    width: 21px;
    /* height: 21px; */
}

.imgEditPencil {
    /* width: 21px !important; */
    height: 14px !important;
}

.imgEditPencilOrange {
    /* width: 21px !important; */
    height: 21px !important;
}

.skillLine {
    background-color: var(--grey);
    height: 1px;
    width: 80%;
    margin-top: 0.5rem;
    border-radius: 10px;
}

.skillBarsContainer {
    width: 80%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem
}

.secondary {
    color: var(--secondary) !important;
}

.secondaryBack {
    background-color: var(--secondary-hover) !important;
}

.secondaryBarLine {
    background-color: var(--secondary) !important;
    width: 50%;
}

.skillBarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.skillLineContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary);
}

.skillLineBackground {
    height: 7px;
    background-color: var(--primary-hover);
    width: 90%;
    border-radius: 10px;
}

.skillBarLine {
    height: 7px;
    background-color: var(--primary);
    width: 50%;
    border-radius: 10px;
}

.ten {
    width: 10%;
}

.twenty {
    width: 20%;
}

.thirty {
    width: 30%;
}

.forty {
    width: 40%;
}

.fifty {
    width: 50%;
}

.sixty {
    width: 60%;
}

.seventy {
    width: 70%;
}

.eighty {
    width: 80%;
}

.ninty {
    width: 90%;
}

.hundred {
    width: 100%;
}