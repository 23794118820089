.popupContainer{
    height: 40rem !important;
    width: 40rem !important;
    flex-direction: column;
    align-items: center;
    contain: content;
    overflow: auto;
}
.returnHolder{
    position: relative;
    height: 4rem;
    width: 100%;
}
.closeFeedback{
    position: absolute;
    top: 1rem;
    left: 37.5rem;
    height: 20px;
}
.busyTitleHolder{
    height: 4rem;
    border-bottom: 2px solid var(--secondary);
    margin-top: 2rem;
}
.busyLabel{
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary);
}
.busyPermission{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}
.submitPermission{
    margin-top: 2rem;
}
.feedbackContainer{
    position: absolute;
    background-color: #8A88ED;
    border-radius: 100%;
    height: 10px;
    aspect-ratio: 1;
    right: 23.76rem;
    top: 4rem;
  }
  .submitFeedbackButton{
    margin-top: 2rem;
    /* height: 9%;
    width: 25%; */
    height: 3.6rem;
    width: 10rem;
  }
  .feedbackButton{
    background-color: var(--primary);
    border-radius: 4px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .closeIcon{
    position: absolute;
    right: 10px;
    top: 10px;
  }