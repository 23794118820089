.moduleContainer {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.moduleMainContainer {
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  border-radius: 8px;
  margin: 12px 12px 12px 6px;
  width: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
  position: relative;
}



.modulecontGoal {
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: calc(100%);
}

.masterImageHolder {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.assignPopupCover .infinite-scroll-component__outerdiv {
  width: 100%
}