.homeContainer {
  background-image: url("../../Assets/HomeBackground.svg");
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}

.homeContainerSocial {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}

.homeContainer > .tabContainer {
  margin-top: 12px;
}

.dateTimeHolder {
  background: #ffffff;
  box-shadow: 0px 2.22225px 11.1112px rgba(0, 0, 0, 0.06);
  border-radius: 14.5px;
  position: absolute;
  left: 24px;
  top: 20px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--primary);
  gap: 0.5rem;
}

#clock {
  margin-left: 0.5rem;
}

.homeSubContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.home-css-pagination {
  background-color: var(--primary-hover);
}

.home-css-pagination > .react-select__control {
  background-color: #e9f3f4;
}
.hiddens {
  overflow: hidden !important;
}
