.candidateLoginGraphics {
  background-color: #77a2c7;
}

.consultantLoginGraphics {
  background-color: #5bc4bf;
}

.consultantLoginGraphics>label {
  position: absolute;
  font-size: 40px;
}

.candidateLoginGraphics>label {
  position: absolute;
  font-size: 40px;
}

.outerGr {
  display: flex;
  position: absolute;
  background: transparent;
  opacity: 0.5;
  bottom: 0;
  right: 0;
}

.girlImg {
  flex: 2 1;
  height: 100%;
}

.WelImg {
  width: 100%;
  height: 30%;
}

.WelImg>img {
  width: 100%;
  height: 100%;
}

.rotTraingle {
  position: absolute;
  z-index: 7;
  bottom: 0%;
  left: 2%;
}

.candText {
  position: absolute;
  right: 21%;
  top: 13%;
  opacity: 0.8;
}

.circleCan {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: multiply;
  z-index: 7;
}

.innerCont {
  height: 90%;
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 1.89445px 14.2084px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.outerCont {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.canOuter {
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.wel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  /* or 143% */
  align-items: center;
  letter-spacing: 0.02em;
}

.canUser {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.02em;
  color: #1296b0;
}

.innerCantext {
  width: 90%;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-top: 12px;
}

.cheersC {
  margin-top: 8%;
  left: 106px;
  top: 560.21px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.teaes {
  font-size: 15px;
  color: var(--primary);
}

.aesslogo {
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.flexIn {
  display: flex;
  contain: content;
  height: 50%;
}

.flexinText {
  flex: 3 1;
  padding: 0 45px;
  overflow: auto;
}

.btnContt {
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20%;
}

.btnCand {
  background: #f7941d;
  font-weight: 400;
  font-size: 15px;
  padding: 3px 12px;
  border-width: 0px;
  border-style: outset;
  border-color: transparent;
  border-image: initial;
  border-radius: 5px;
  text-align: center;
  height: 35px;
  color: white;
  box-shadow: inset 0px 4.89557px 12.2389px rgba(0, 0, 0, 0.06);
  border-radius: 3.67168px;
}

.btnCand1 {
  background: #f7941d;
  font-weight: 400;
  font-size: 15px;
  /* padding: 3px 12px; */
  border-width: 0px;
  border-style: outset;
  border-color: transparent;
  border-image: initial;
  border-radius: 5px;
  text-align: center;
  /* height: 35px; */
  color: white;
  box-shadow: inset 0px 4.89557px 12.2389px rgba(0, 0, 0, 0.06);
  border-radius: 3.67168px;
}

.orangeCan {
  position: absolute;
  right: 44%;
  bottom: 45%;
  width: 43.27px;
  height: 43.27px;
}

.CirclePink {
  position: absolute;
  top: 2%;
  z-index: 7;
  right: 38%;
}

.CircTraipur {
  position: absolute;
  bottom: 23%;
  left: 29%;
}

.circleLittle {
  position: absolute;
  left: 35%;
  top: 13%;
}

.circlelilPur {
  position: absolute;
  left: 46%;
  bottom: 29%;
}

.CircDouble {
  position: absolute;
  right: 15%;
  top: 47%;
}

.TraingSky {
  position: absolute;
  right: 4%;
  bottom: 23%;
}

.onboardingInnerContainer {
  height: 90%;
  width: 90%;
  background-color: #ffffff;
  box-shadow: 0px 1.89445px 14.2084px rgb(0 0 0 / 6%);
  border-radius: 6px;
  z-index: 1;
  display: flex;
  contain: content;
}

.seperationInnerContainer {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  display: flex;
  contain: content;
}

.onboardingFormButtonContainer {
  gap: 12px;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}

.noMargin {
  margin: 0 !important;
}

.onboardingMainContainer {
  width: 78%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

.onboardingSidePanelContainer {
  width: 80%;
  margin: 30% 0 30% 0;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 36px;
  margin-left: 10%;
  position: relative;
}

.onboardingSidePanel {
  width: 22%;
  background: linear-gradient(0deg, rgb(119, 162, 199), rgb(119, 162, 199)),
    rgb(89, 145, 194);
  position: relative;
  display: flex;
  justify-content: center;
}

.seperationSidePanel {
  width: 22%;
  background: #5bc4bf;
  position: relative;
  display: flex;
  justify-content: center;
}

.onboardingSidePanelTile {
  display: flex;
  align-items: center;
  gap: 18px;
  z-index: 2;
  margin-left: 4px;
}

.onboardingSidePanelTileActive {
  gap: 14px !important;
  margin-left: 0 !important;
}

.onboardingSidePanelTile>img {
  width: 36px;
  height: 36px;
}

.onboardingSidePanelTile>label {
  color: #c5ddf2;
  font-size: 16px;
  font-weight: 400;
}

.onboardingSidePanelTileActive>label {
  color: white;
  font-weight: 500;
}

.onboardingSidePanelTileActive>img {
  width: 44px;
  height: 44px;
}

.onboardingFormContainer {
  width: 90%;
  height: 75%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 6%);
  border-radius: 6px;
  overflow: auto;
}

.seperationFormContainer {
  width: 100%;
  height: 93%;
  display: flex;
  background: var(--form-background);
  flex-direction: column;
  border-radius: 6px;
}

.video-js {
  width: 500px;
  height: auto;
  aspect-ratio: 16 / 9;
}

.onboardingTabBodyContainer {
  flex-grow: 1;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--form-background);
  padding-top: 12px;
}

.onboardingTabBodyContainer>.formcustom {
  width: 100%;
}

.videoCard {
  width: 33.333333333%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  contain: content;
}

.videoCard>label {
  color: var(--primary);
  font-weight: 500;
  white-space: nowrap;
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .videoCard {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .videoCard {
    width: 100%;
  }
}

/* Employee */

.employeeProfileImgHolder {
  margin-left: 45px;
  margin-bottom: 24px;
  border-radius: 50%;
  box-shadow: 0px 1px 10px 2px rgb(0 0 0 / 8%);
  background-color: white;
  margin-top: -9%;
  height: 150px;
  width: 150px;
  contain: content;
  /* padding: 6px; */
}

.employeeProfileImg {
  width: 100%;
  border-radius: 50%;
  margin: 6px 6px 6px 0px;
  height: 100%;
}

.mainiconf {
  /* margin-top: 7%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgotHead {
  padding-top: 10px;
  color: #1296b0;
  font-weight: 500;
  font-size: 21px;
  line-height: 41px;
}

.verifyOTPHeading {
  color: #BFBFBF;
  font-size: 13px;
}

.inheading {
  font-weight: 500;
  line-height: 14px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.headingTxt {
  color: #555;
  font-size: 14px;
  font-weight: 500;
}

.otpSeparator {
  color: #fff;
  margin: 0 1px;
}

.forgheaddiv {
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 63%;
  align-items: center;
  width: 100%;
}

.forgotForm {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 400px;
}

.emfor {
  /* margin: 0px 10px; */
  margin-top: 25px;
  font-weight: 500;
  line-height: 9px;
  color: #1296b0;
}

.btnfor {
  margin: 15px 0;
  padding: 13px 12px;
  outline: 0;
  border: 0.775758px solid #1296b0 !important;
  border-radius: 6.20606px !important;
  border: 0;
  border-radius: 3px;
  font-size: 15px;
  width: 100%;
}

.forgotInput {
  padding: 15px 12px !important;
}

.inputfor {
  background: #ffffff;
  border: 0.775758px solid #1296b0;
  border-radius: 6.20606px;
  width: 100% !important;
}

.inputfor:hover {
  background: #ffffff;
  border: 0.775758px solid #1296b0;
}

.backtol {
  position: absolute;
  right: 40px;
  top: 30px;
  cursor: pointer;
}

.backtol span {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  font-family: Poppins;
}

.input-otp {
  /* color: var(--black); */
  background: #E3FDFC;
  border-radius: 2px;
  font-size: 14px;
  outline: 0;
  width: 43px !important;
  height: 43px !important;
  flex: 1;
  border: none;
  font-weight: 400;
  border-bottom: 4px solid #C1EEF2;
  margin: 0px 3px;
}

.resendCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  line-height: 27px;
}

.uploadDocumentFooter {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--primary);
  gap: 12px;
  padding: 6px 12px;
}

/* mobile view greetings page*/

/* 
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .videoCard {
    width: 50%;
  }
} */

@media only screen and (max-width: 700px) {
  .outerCont {
    position: relative;
    display: flex;
    width: 100%;
    /* height: 100%; */
    contain: content;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }

  .innerCont {
    height: fit-content;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px 1.89445px 14.2084px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-top: 257px;
    margin-bottom: 66px;
  }

  .flexIn {
    display: flex;
    contain: content;
    height: 687px;
    flex-direction: column-reverse;
  }

  .girlImg {
    display: flex;
    /* flex: 2 1; */
    height: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }

  .flexinText {
    /* flex: 3 1; */

    overflow: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 32px;
    width: 90%;
  }

  .btnContt {
    padding: 45px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    height: 100%;
    flex-direction: column;
  }

  .btnCand {
    background: #f7941d;
    font-weight: 400;
    font-size: 15px;
    padding: 3px 12px;
    border-width: 0px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
    border-radius: 5px;
    text-align: center;
    height: 35px;
    color: white;
    box-shadow: inset 0px 4.89557px 12.2389px rgba(0, 0, 0, 0.06);
    border-radius: 3.67168px;
    margin-bottom: 57px;
  }

  .candText {
    position: absolute;
    right: 21%;
    top: 9%;
    opacity: 0.8;
  }

  .TraingSky {
    position: absolute;
    right: 4%;
    z-index: 7;
    bottom: 13%;
  }

  .CircTraipur {
    position: absolute;
    bottom: 28%;
    left: 9%;
  }

  .outerGr {
    display: flex;
    position: fixed;
    background: transparent;
    opacity: 0.5;
    bottom: 0;
    right: 0;
  }
}

.passwordValidateContainer {
  position: relative;
  left: -4%;
}

.passwordValidateContainer h2 {
  font-size: 17px;
  color: #1296b0;
  margin-bottom: 5px;
}

.passwordValidateContainer p {
  font-size: 15px;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 500;
}










@media only screen and (max-width: 500px) {
  .flexIn {
    display: flex;
    contain: content;
    height: 687px;
    flex-direction: column-reverse;
    margin-top: 20%;
  }
}

/* mobile view learning page*/
@media only screen and (max-width: 950px) {
  .onboardingSidePanel {
    display: none;
  }

  .onboardingMainContainer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 950px) {
  .onboardingFormButtonContainer {
    gap: 12px;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    flex-direction: column;
  }

  .onboardingFormButtonContainer {
    gap: 12px;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .onboardingFormContainer>.formcustom {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3px;
    padding-top: 24px;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1250px) {
  .onboardingFormContainer>.threeColumn {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 3px;
    padding-top: 24px;
  }
}

@media only screen and (max-width: 670px) {
  .onboardingFormContainer>.threeColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 3px;
    padding-top: 24px;
  }
}