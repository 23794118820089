.newGroupContainer .newChatListContentContainer {
  display: flex;
  align-items: center;
}
.newGroupContainer .newChatListContentContainer .newChatActiveImage {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  margin: 12px;
  position: relative;
}
.newGroupContainer .newChatListContentContainer .newChatActiveImage > img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.newGroupContainer .newChatListContentContainer .newChatPostMainTextContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
}
.newGroupContainer
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListName {
  font-size: 15px !important;
  color: black;
  font-weight: 500;
}
.newGroupContainer
  .newChatListContentContainer
  .newChatPostMainTextContainer
  .chatListRole {
  font-size: 13px;
  color: rgb(114, 111, 111);
  font-weight: 500;
}
.newGroupContainer .newChatListContentContainer:active {
  background-color: rgba(216, 240, 242, 0.4);
}
.newGroupContainer .newChatListContentContainer:active .chatListName {
  color: var(--primary);
}
.newGroupContainer .selectChipContainer {
  display: flex;
  background-color: #fff;
  padding: 3px;
  border-radius: 15px;
  position: relative;
  align-items: center;
  margin: 1.5px 2.5px ;
}
.newGroupContainer .chipImg {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.newGroupContainer .chipLabel {
  color: #555;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  padding: 0 10px 0 4px;
}
.newGroupContainer .selectChipContainer > svg {
  right: 4px;
}
.newGroupSearchContainer {
  position: relative;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  margin: 10px;
  /* padding: 0 10px */
  /* width: 93%; */
}
.newGroupSearchContainer .chatListSearch {
  box-sizing: border-box;
  margin: 0 !important;
  padding: 5px 10px;
  outline: none;
  border: none;
  width: 100%;
}

.newGroupSearchContainer svg {
  position: relative;
  right: 20px;
}

.newScrollbar::-webkit-scrollbar {
}

.newScrollbar::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 7px;
}
.newScrollbar::-webkit-scrollbar-button:single-button:increment {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='0,0 100,0 50,50'/></svg>");
}
.newScrollbar::-webkit-scrollbar-button:single-button:decrement {
  height: 12px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(231, 231, 231)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.newScrollbar::-webkit-scrollbar {
  width: 13px;
}

.newScrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.newScrollbar::-webkit-scrollbar-thumb {
  border: 5px solid #fff;
  background-color: #e7e7e7;
  border-radius: 15px;
}
.newGroupContainer .groupCreationNxtBtn {
  position: absolute;
  bottom: 20px;
  right: 25px;
}

.newGroupContainer .newGroupCreationContainer {
  /* width: 100%;
  height: 100%; */
}
.newGroupContainer
  .newGroupCreationContainer
  .newGroupImageContainer
  .newGroupImageWrapper {
  display: flex;
  justify-content: center;
}
.newGroupContainer
  .newGroupCreationContainer
  .newGroupImageContainer
  .newGroupImageWrapper
  svg {
  mix-blend-mode: darken;
}

/* .box {
  display: flex;
  flex-direction: column;
  contain: content;
  position: relative;
  max-width: 400px;
  height: fit-content;
  max-height: 80vh;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #999;
  max-height: 80%;
  border-radius: 6px;
  background: #faf9f8;
  min-height: 70vh;
} */
.newGroupCreationPopupBox1{
  
}
.newGroupCreationPopupBox2 {
  width: 20%;
  height: 80%;
  /* overflow-y: auto; */
}
.myPopupBox {

}
.selectedParticipantContainer{
  display: flex;
  flex-wrap: wrap;
}

.newGroupContainer .newGroupCreationContainer .newGroupImageContainer .newGroupImageWrapper svg {
  mix-blend-mode: darken;
  position: relative;
  /* top: -100px; */
  /* left: 100px; */
}
.newGroupCreationImg svg mask{
  mask-type:alpha;
}
.newGroupImageContainer{
 

}
.newGroupImageContainer .newGroupImageWrapper{
 
}
/* .profileIconImg .imgText input{
  outline: none;
  border: none;
  background-color: #faf9f8;
} */
.profileIconImg .imgText span input{
  outline: none;
  border: none;
  background-color: #faf9f8;
 color: #1296B0;
 font-weight: 500;
 font-size: 13px;
 /* padding-left: 10px; */
}
.newGroupImageWrapper .profileIconImg .newGroupCreationImg .newGroupProfileImg{
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
}
.newGroupImageWrapper .profileIconImg .newGroupCreationImg{
  position: relative;
  top: -135px;
  left: 28px;
}
.profileIconImg .newGroupCreationCameraIcon > svg{
  position: relative;
    top: -155px;
    left: 100px;
}
.newGroupImageContainer .imgText {
  position: relative;
  top: -110px;
}
.newGroupImageContainer .imgText .newGroupHrLine{
  width: 180px;
}
.newGroupImageContainer .imgText p{
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 400;
}
.newGroupImageContainer .imgText h4 span svg {
 margin-left: 10px;
}

/* .newGroupCreationContainer .newGroupParticipantListContainer .newChatListContentContainer{
 border-top: 1px solid #F1F2F2; 
} */
/* .newGroupCreationContainer .newGroupParticipantListContainer .newGroupLstWithDeleteIcon{
 display: flex;
 justify-content: space-between;
 align-items: center;
} */
.newGroupCreationContainer .newGroupParticipantListContainer .newGroupLstWithDeleteIcon .newGroupListRowContainer{
  display: flex;
 justify-content: space-between;
 align-items: center;
  border-top: 1px solid #F1F2F2;
}
