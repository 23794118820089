.documentsContainer{
    width: 95%;
    margin: 1rem;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-wrap: wrap;
}




.documentContainer{
    height: 12rem;
    width: 12rem;
    margin: 0.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: #FFFFFF;
box-shadow: 0px 2.6634px 13.317px rgba(0, 0, 0, 0.06);
border-radius: 5.32681px;
padding-bottom: 1rem;
}
.folderOption{
    position: absolute;
    top: 0.5rem;
    right: 0;
    height: 10%;
    width: 15% !important;
}
.documentContainer > img {
    width: 60%;
    margin-bottom: 0.5rem;
}