.settingsContainer {
  justify-content: flex-start;
  gap: 12px;
}
.settingContainerBody {
  width: 100%;
  padding: 24px 12px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.settingListContainer {
  display: flex;
  gap: 12px;
  width: calc(100% - 12px);
  height: fit-content;
  padding: 6px;
  font-size: 15px;
}
.notLastSettingListContainer {
  border-bottom: 1px solid #e6e6e6;
}
.settingCard {
  max-height: 300px;
}
.globalSettings {
  color: #98acab;
}
.settingCardHolder {
  height: 100%;
  overflow: auto;
}
.doubleBodyContainer {
  display: flex;
}
.doubleBodyContainer > div > .settingListContainer {
  width: 100%;
}
.doubleHeadingContainer {
  display: flex;
  width: 100%;
}
.hrmsHover {
  background-color: var(--green-hover);
}
.homeHover {
  background-color: var(--primary-hover);
}
.officeHover {
  background-color: var(--magenta-hover);
}
.itsmHover {
  background-color: var(--moonstone-hover);
}
.payrollHover {
  background-color: var(--pink-hover);
}
.taxHover {
  background-color: var(--purple-hover);
}
.atsHover {
  background-color: var(--blue-hover);
}
.pmsHover {
  background-color: var(--orange-hover);
}
.ldHover {
  background-color: var(--teal-hover);
}
.travelHover {
  background-color: var(--secondary-hover);
}
.settingHover {
  background-color: #e9f5f5;
}
.globalHover {
  background-color: #76797912;
}
.hrmsHovers:hover {
  background-color: var(--green-hover);
}
.homeHovers:hover {
  background-color: var(--primary-hover);
}
.officeHovers:hover {
  background-color: var(--magenta-hover);
}
.itsmHovers:hover {
  background-color: var(--moonstone-hover);
}
.payrollHovers:hover {
  background-color: var(--pink-hover);
}
.taxHovers:hover {
  background-color: var(--purple-hover);
}
.atsHovers:hover {
  background-color: var(--blue-hover);
}
.pmsHovers:hover {
  background-color: var(--orange-hover);
}
.ldHovers:hover {
  background-color: var(--teal-hover);
}
.travelHovers:hover {
  background-color: var(--secondary-hover);
}
.settingHovers:hover {
  background-color: #e9f5f5;
}
.globalHovers:hover {
  background-color: #76797912;
}
.search-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-input {
  box-sizing: border-box;
  background: #eff9fa;
  border: 0.5px solid #d8f0f2;
  border-radius: 4.65352px;
  margin: 12px 0;
  width: 40%;
  padding: 6px 12px;
}
.repeata {
  margin-top: 332px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.myactivstate {
  width: 29.5rem !important;
  top: 10px;
  transition: all 0.1s ease-in-out;
}
.labelHover {
  border: 1px solid var(--primary-hover);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  font-weight: 500;
  min-height: 55px;
  padding: 15px 13px 10px 11px;
  width: 100%;
  height: 96%;
  /* background:var(--primary-hover); */
  border: 1px solid #d8f0f2;
  box-shadow: 0px 3.56872px 17.8436px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.scondMenu {
  position: absolute;
  margin: 0;
  display: flex;
  flex-direction: row;
  /* top: 10px; */
  left: calc(80px + 240px);
  overflow: auto;
  height: fit-content;
  width: 0;
  transition: all 0.1s ease-in-out;
  background-color: white;
  overflow-x: hidden;
  border-radius: 8px;
}
.innstdiv {
  cursor: pointer;
}
.innstdiv:hover {
  text-decoration: underline;
}
