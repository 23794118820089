.pageContainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.sidebarVerticalBarSelectedLabel {
    position: relative;
    left: 30px;
}

.approvalSidebarTile-cursor {
    display: flex;
    margin: 0.5rem;
    padding: 0.3rem;
}

.sideVerticalbarIcon {
    position: relative;
    left: 10px;
}

.sidebarVerticalBarLabel {
    position: relative;
    left: 30px;
}

.dashboard {
    display: flex;
    height: 15rem;
    position: relative;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.dashboardLeft {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    height: 100%;
    align-items: center;
    flex-shrink: 0;
}

.profileImg {
    margin-left: 5.5rem;
    height: 12rem;
    width: 12rem;
}

.greeting {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-left: 3rem;
    margin-top: 3rem;
}

.greetingHey {
    margin: 0;
    font-weight: 500;
    font-size: 2rem;
    color: var(--primary);
}

.greetingName {
    margin: 0;
    font-weight: 500;
    font-size: 2rem;
}

.greetingRole {
    /* margin: 0;
    font-weight: 500;
    font-size: 1.5rem; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
}

.pendingApprovals {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 60px;
    /* or 182% */

    display: flex;
    align-items: center;
    letter-spacing: -0.035em;

    /* Notification_1 */

    color: #F71D1D;

}

.shortLine {
    background-color: var(--primary);
    height: 3px;
    width: 20%;
    margin-top: 1px;
    border-radius: 10px;
}

.dashboardApprovalsRight {
    background-image: url("../../Assets/MyServicesDashboardBackRight.svg");
    background-position: right;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
}

.dashboardApproval {
    display: flex;
    width: 82%;
    align-items: center;
    height: 50%;

}

.flex-dashboardApprovalDetail {
    display: flex;
    margin: 1rem;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    border-radius: 10px;
    height: 90px;
    width: 60%;
    background: #FFFFFF;
    background-image: url(../../Assets/ApprovalBackground.svg);
    background-repeat: no-repeat;
    background-position: right;


}

.ApprovalBy {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    font-weight: 500;
    width: 40%;

}

.dashboardApprovalPercent {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34.9623px;
    line-height: 55px;
    margin: 5px 10px;
    /* or 157% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    /* PeopleSOl Primery */

    color: #1296B0;
}

.dashboardApprovalAmount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10.7323px;
    line-height: 20px;
    /* or 183% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    /* PeopleSol */
    margin: 5px 15px;
    color: #BFBFBF;

}

/* .editPencil
{
    position: absolute;
    top: 23px;
    right: 23px;
    margin: 0.5rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 0.6rem;
    box-shadow: 0px 4px 6px rgba(85, 85, 85, 0.15);
} */
.editPencil:hover {
    cursor: pointer;
}

.editPencilIcon {
    width: 4.8rem;
    height: 4.8rem;
}

/* .socialIconContainer{
    position: absolute;
    display: flex;
    top: 198px;
    right: 26px;
    margin: 0.5rem;
} */

.socialIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem
}

.socialIcon:hover {
    cursor: pointer;
}



/* .flex-dashboardApprovalDetail{
    width: 100%;
    display: flex;
    overflow-y: hidden;
    height: calc(100% - 15rem);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin: 1rem;
    background: #FFFFFF;
    background-image: url(../../Assets/NotificationsBackground.svg);
    background-repeat: no-repeat ;
} */
/* .servicesSidebar{
    width: 25rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
} */
.approvalsTabContainer {
    width: 95%;
    justify-content: space-between;
}

.approvalSidebarTile {
    display: flex;
    align-items: center;
    position: relative;
}

.sidebarTileSelected {
    background-color: var(--primary-hover);
    width: initial;
    padding: 10px;
}

.sidebarTileSelected>.sidebarVerticalBar {
    background-color: var(--primary);
}

.sidebarVerticalBar {
    position: absolute;
    left: 0;
    height: 100%;
    width: 10px;
    background-color: transparent;

}

.servicesSidebarTitleLabel {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.servicesMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

/* .starPerformer{
    position: absolute;
    left:10px;
} */
.profileCompletion {
    position: absolute;
    left: 250px;
    top: 160px;
}

.approvalSearch {
    position: relative;
    top: 11px;
}

.sidebarVerticalBar {
    position: absolute;
    left: 0;
    height: 100%;
    width: 10px;
    background-color: transparent;
}

.sidebarAttendanceImg {
    position: relative;
    left: 5px;
}

/* using this className in any div (with "cursor" and not "-cursor") will highlight it */

/* .sidebarTileSelected{
    background-color: var(--primary-hover);
}
.sidebarTileSelected > .sidebarVerticalBar{
    background-color: var(--primary);
} */

.dashboardApprovalServicesContainer {
    display: flex;
    /* margin: 1rem; */
    align-items: center;
}

.approvalViewIcon {
    position: absolute;
    left: 62rem;
    /* background-color: white; */
}