.userOnboardPopup{
    width: calc(100% - 10rem);
    height: calc(100% - 10rem);
    flex-direction: column;
    align-items: center;
    contain: content;
    overflow: auto;
    max-width: 100%;
}
.onboardContentHolder{
    display: flex;
    z-index: 2;
    height: calc(100% - 130px);
    width: calc(100% - 195px);
    padding-top: 1rem;
    justify-content: space-between;
    position: relative;
}
.onboardInfoHolder{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.onboardHeading{
    color: var(--secondary);
    font-weight: 500;
}
.onboardGreeting{
    font-size: 24px;
    font-weight: 500;
}
.onboardText{
    font-size: 20px;
    font-weight: 500;
}
.onboardStartButton{
    height: 45px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.onboardfooterButton{
    position: absolute;
    bottom: -3rem;
    width: 100%;
    display: flex;

}
.onboardNextButtonHolder{
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    width: 13rem;
}
.onboardButtons{
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 18px;
}
.onboardPrev{
    background-color: #D8F0F2;
    color: var(--primary);
    width: 90px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.onboardNext{
    background-color: var(--primary);
    color: white;
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scanText{
    display: flex;
    align-items: flex-end;
    padding-left: 11px;
    font-weight: 600;
}
.onboardReturnIconHolder{
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px;
}
.onboardingImageInfoContainer{
    height: calc(100% - 75px);
    width: calc(100% - 7rem);
    display: flex;
    justify-content: center;
    align-items: center;
}
.peoplesolFeatureSvgClass{
    width: calc(100% - 11rem);
    height: calc(100% - 10rem);
    margin: 2rem;
}
.onboardBodyContainer{
    width: 100%;
    height: 100%;
    display: flex;
}
.imageHolder{
    width: 60%;
}
