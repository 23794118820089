body {
  margin: 0;
  overflow: auto;                            
}
html{
  overflow: auto;
}
html, body, #app, #app>div {
  height: 100%
}
html, body, #root, .App {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0rem;
}

body::-webkit-scrollbar-track {
  background-color: var(--light-grey);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--grey);
}
.profile {
  border-radius: 50%;
}

.profile-lg {
  width: 3.5rem;
  height: 3.5rem;
}

.profile-md {
  width: 3rem;
  height: 3rem;
}

.profile-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.profile-xs {
  width: 2rem;
  height: 2rem;
}
