.btnHrContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.timeAttenConfigContainer hr {
  width: 100%;
  height: 2px;
  border: 1px solid #e0dcdc;
  border-radius: 5px;
}

.btnContainer {
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.myCancelBtn {
  color: red !important;
}

.btnContainer .configCancelButton {
  padding: 8px 20px;
  right: 140px !important;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btnContainer .configUpdateButton {
  background-color: #1296b0;
  color: #fff;
  padding: 7px 20px;
}

.timeAttenConfigContainer {
  overflow: auto;
}

.attenConfigContainer,
.timeConfigContainer {
  padding-top: 40px;
}

.timeAttenConfigContainer .timeAttenConfigWrapper {
  padding: 30px 40px 0px 40px;
  /* overflow: auto; */
  margin-bottom: 100px;
}

.timeAttenConfigContainer .timeAttenConfigWrapper .timeConfigHeader,
.timeAttenConfigContainer .timeAttenConfigWrapper .attenConfigHeader {
  border-bottom: 1px solid #e0dcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.timeConfigHeader h2,
.attenConfigHeader h2 {
  font-size: 17px;
}

.timeConfigHeader span,
.attenConfigHeader span {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.timeConfigHeader p,
.attenConfigHeader p {
  font-size: 15px;
  margin-left: 5px;
  /* padding-bottom: 5px; */
  color: #fb7158;
  font-weight: 500;
}

.timeConfigWrapper .configRow1,
.timeConfigWrapper .configRow2,
.attenConfigWrapper .configRow1,
.attenConfigWrapper .configRow2 {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.timeConfigWrapper .configRow3,
.attenConfigWrapper .configRow3 {
  height: 100%;
  /* min-width: 70%; */
  width: 66%;
  max-width: 100%;
  /* max-width: 100%; */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.timeConfigWrapper .configRow3 .configBox,
.attenConfigWrapper .configRow3 .configBox {
  width: 48% !important;
}

.timeConfigWrapper,
.attenConfigWrapper {
  width: 100%;
}

.timeConfigWrapper .configBox,
.attenConfigWrapper .configBox {
  width: 32%;
  max-width: 99%;
  min-height: 40%;
  max-height: 50%;
  display: flex;
  align-items: center;
  border: 1px solid #e0dcdc;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 30px;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
}

.configBox .configTxt {
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
}

.configBox .configValue {
  margin-left: 15px;
  padding: 10px 13px;
  border-radius: 6px;
  background: var(--Pastel, #d8f0f2);
  color: #1296b0;
}

.changeConfigValue {
  display: flex;
}

.changeConfigValue div {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.dropdownChangeConfigValue div {
  display: flex;
  flex-direction: column;
}

.no {
  margin-left: 4px;
}

.configContainer {
  display: block;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.configContainer input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.checkmark::before {
  content: "";
  position: absolute;
  background-color: #eeeeee !important;
  display: block;
}

.configContainer input:checked~.checkmark:after {
  display: block;
  background-color: #1296b0;
}

.configContainer input~.checkmark:before {
  display: block;
}

.configContainer .checkmark::after {
  top: 0.34rem;
  left: 0.34rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1296b0;
}

.configContainer .checkmark::before {
  top: 0.34rem;
  left: 0.34rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #979797;
}

.configEditBox {
  background-color: #fbfbfb;
}

.timeConfigCls {
  border: 1px solid #eeeeee;
}

.timeConfigCls .css-jznujr-ContentComponent {
  flex-wrap: wrap !important;
  /* overflow: scroll; */
  display: -webkit-box;
}

.timeConfigCls .react-dropdown-select {
  max-width: inherit !important;
  max-height: 80px !important;
}

.createConfigCls {
  border: 1px solid #eeeeee;
}

.createConfigCls .react-dropdown-select {
  max-width: 150px !important;
  max-height: 80px !important;
}

.forNo {
  background: #fb786169 !important;
  color: #ad1b00 !important;
}

.reguConfig {
  display: flex;
  align-items: center;
}

.configIconBtn {
  border-radius: 0 !important;
}

.numberOfRegularize input {
  width: 40px;
  height: 40px;
  border: none;
  text-align: center;
  border: 1px solid #eeeeee;
  outline: none;
  border-radius: 5px;
  padding: 8px 0px;
  color: #696969;
}

.configBox .css-1odnlzj-ReactDropdownSelect {
  color: #1296b0;
  border-color: #1296b038 !important;
}

.changeConfigValue textarea {
  outline: none;
  border: none;
  border: 1px solid #1296b038;
  border-radius: 5px;
  padding: 10px;
  color: #555;
  width: 100%;
}

.departmentsListContainer {
  border: 1px solid #1296b038;
  border-radius: 5px;
  overflow-y: auto;
  /* overflow-x: hidden; */
  max-height: 80px;
  padding: 5px 10px;
}

.departmentsListContainer article {
  color: #1296b0;
}

.departmentDropDownListContainer .react-dropdown-select {
  flex-direction: unset !important;
}

.departmentDropDownList {}

.timeInputField {
  width: 60px !important;
  min-width: 60px !important;
}