/* .appriaisalReviewTable td {
    padding: 10px;
} */
.appriaisalReviewTable thead td {
    padding: 10px;
}

.appriaisalReviewTableDetail td {
    padding: 17px 10px;
}

.appriaisalReviewTableinput {
    min-width: 70px !important;
    width: 70px;
}

.topDiv {
    display: flex;
    /* padding-left: 20px; */
    justify-content: flex-end;
    margin: 25px 0px;
}

.topDivPad {
    width: 846px;
    display: flex;
    justify-content: space-around;
    /* margin-right: 14px; */
}

.topDivJoiningCTC {
    background-color: #EFF9FA;
    border-radius: 6px;
    /* width: 360px; */
    width: fit-content;
    height: 86px;
    padding: 6px 15px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.topDivJoiningCTCdiv {
    border-left: 1.5px solid #1296B0;
    /* width: 25%; */
    min-width: 97px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 78%;
}

.topDivJoiningCTCdivHeading {
    font-size: 11px;
}

.topDivJoiningCTCdivAmount {
    font-size: 18px;
}

.topDivJoiningDate {
    background-color: #FFEEFA;
    border-radius: 6px;
    /* width: 213px; */
    width: fit-content;

    height: 86px;
    padding: 6px 15px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.topDivJoiningDatediv {
    border-left: 1.5px solid #DE64A1;
    /* width: 45%; */
    min-width: 97px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 78%;
}

.topDivTotalGrowth {
    background-color: #F0F0FF;
    border-radius: 6px;
    /* width: 213px; */
    width: fit-content;

    height: 86px;
    padding: 6px 15px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.topDivTotalGrowthdiv {
    border-left: 1.5px solid #8A88ED;
    /* width: 45%; */
    min-width: 97px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 78%;
}

.bottomDiv {
    margin: 30px 0px;
}

.bottomDivPerformanceSize {
    width: 18%;
    padding: 10px;

}

.bottomDivPerformance {
    display: flex;
}

.bottomDivPad {
    /* display: flex;
    justify-content: flex-end; */
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

.circleAppraisal {
    border-radius: 50%;
    position: absolute;
    /* right: 0; */
    right: -16px;
    top: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    text-align: center;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: #1296B0;
    z-index: 2;
}

.borderAppraisal {
    width: 2px;
    background-color: #D8F0F2;
    height: 50%;
    position: absolute;
    right: 0px;
    top: 50%;
}

.borderAppraisalFull {
    width: 2px;
    background-color: #D8F0F2;
    height: 100%;
    position: absolute;
    right: 0px;
}

.submitAppraisalTable {
    width: 100%;
}

.submitAppraisalTable>thead>tr>td {
    padding: 15px 11px;
}

.submitAppraisalTable>tbody>tr>td {
    padding: 20px 11px;
}

.classStyleSubmitAppraisal {
    text-align: center;
}

.formikPMSBorder .react-select-container {
    border: 0.75px solid #BFBFBF !important;
}

.cancelbuttonPms {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px #0000000D inset;
    color: #F71D1D
}

.SendLDPopupCourseBox {
    display: flex;
    width: 700px;
    height: 400px;
    background-color: #fff;
    margin: 30px 70px;
    border-radius: 6px;
}

.SendLDPopupAvailableCourse {
    width: 50%;
    border-right: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
}

.SendLDPopupRecommendedCourse {
    width: 50%;

}

.SendLDPopupAvailableHeader {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 4px 0px #0000000A;
    height: 68px;
}

.searchInputLDPopup {
    box-sizing: border-box;
    padding-left: 10px;
    display: flex;
    font-size: 14px;
    width: 100%;
    height: 100%;
    border: none;
    background: #ffffff;
    border-bottom: 1px solid #1296b0;
    font-weight: 400;
    padding-right: 18px;


}

.searchInputLDPopup::placeholder {
    font-size: 14px;
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b9b9b9ed;
    opacity: 0.8;
    font-weight: 400;
}

.searchInputLDPopup:focus {
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #1296b0 !important;
}