.landing {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.loginForm {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero {
  position: absolute;
  right: -20px;
  top: 0px;
  transform: rotate(90deg);
}

.loginGraphics {
  flex-grow: 1;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}

.loginGraphicsBox {
  box-sizing: border-box;
  border: 1px solid white;
  width: 65%;
  aspect-ratio: 0.8;
  background: linear-gradient(146.2deg,
      rgba(255, 255, 255, 0.6) 1.19%,
      rgba(255, 255, 255, 0.1) 100%);
  border-radius: 14.7003px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginGraphicImage {
  position: absolute;
  bottom: 0;
  left: -15%;
  aspect-ratio: 0.8;
  height: 110%;
}

.newLoginGraphicImage {
  height: 100%;
  position: absolute;
  left: 0;
}

.LoginImageDash {
  position: absolute;
  z-index: 2;
  left: -20%;
}

.LoginImageProfile {
  position: absolute;
  z-index: 2;
  margin-left: 30%;
  margin-bottom: 15%;
}

.LoginImageGraph {
  position: absolute;
  z-index: 2;
  right: -10%;
  margin-top: 65%;
}

.LoginImagePerson {
  position: absolute;
  z-index: 2;
  right: 10%;
  margin-bottom: 15%;
}

.loginFormBox {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.heroLogo {
  width: 277.72px;
  height: 71.45px;
}

.subHeroLogo {
  width: 150px;
  height: 45px;
}

.loginFooter {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loginFooterMain {
  flex-direction: column;
  width: 95%;
  max-width: 500px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.subFooter {
  display: flex;
  gap: 10px;
}

.subFooterText {
  display: flex;
  margin-left: 10px;
  align-items: center;
  color: var(--primary);
  font-size: 15px;
  text-decoration: none;
}

.footerLine {
  border-top: 1px solid var(--grey);
  width: 70%;
}

.footerLineText {
  color: #000000;
  font-size: 1rem;
  text-decoration: none;
}

.footerEndLine {
  display: flex;
  gap: 5px;
  margin-top: 6px;
  flex-direction: column;
  align-items: center;
}

.pages {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0rem 1rem 1rem 1rem;
  height: 100%;
}

.profile-setting {
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.all-suggestions {
  display: none;
}

@media only screen and (max-width: 840px) {
  .loginGraphics {
    display: none;
  }

  .loginForm {
    width: 100%;
  }
}

.tabContainer {
  width: fit-content;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  position: relative;
  align-self: center;
  max-width: 100%;
}

.tabContainerLine {
  height: 1px;
  width: 100%;
  background-color: var(--grey);
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.tabHeading {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-transform: none !important;
}

.tabActive {
  color: var(--primary);
}

.tabActiveSecondary {
  color: var(--secondary);
}

td {
  font-family: "Poppins" !important;
  font-size: 14px !important;
}

.teamAttendanceItemContainer>td {
  font-size: 12px !important;
}

.no-select-peoplesol {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}