.SelectVideoFromDropdown {
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.02);
    height: 40px;
    margin: 12px;
}

.SelectVideoFromDropdown {
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.02);
    height: 40px;
    margin: 12px;
}

#SelectVideoFromDropdownId {
    height: 0px;
}

.SelectHeight {
    height: 50px !important;
}

.SelectVideoFromDropdownImg:hover {
    color: hsl(0, 0%, 60%);

}

.SelectVideoFromDropdown:hover {
    border: 1px solid var(--primary-hover) !important;
}

.input-shorttextLD {
    color: var(--black);
    background-color: var(--white);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    font-size: 14px;
    margin: 12px;
    padding: 12px;
    border: 1px solid transparent;
    box-sizing: border-box;
    outline: 0;
    height: 40px;
    width: calc(100% - 24px);
    flex: 1 1;
    font-weight: 400;
}

/* .SelectVideoLDMaster>.input-shorttext:focus {
    border: none;
} */
.videowarningPopup {
    /* background-color: white; */
    border: 1px solid grey;
    /* height: 80px; */
    width: 400px;
    padding: 30px;
    border-radius: 8px;
    border: 1px solid #999;
    background: #faf9f8;
}

.videowarning {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: red;
    margin-left: 10px;
}

.videowarningcontent {
    font-weight: 400;
    font-size: 14px;
    padding: 10px 13px;
    color: #555555;
    display: block;
    font-family: "Poppins";
}

.FileuploadLDbutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 6px;
    width: 114%;

}

.FileuploadLDbuttonDiv1 {
    display: flex;

}

.buttonLD {
    cursor: pointer;
    margin: 7px;
    padding: 0px 6px;
    outline: 0;
    border: 0;
    border-radius: 4px;
    font-size: 13px;
    text-align: center;
    min-width: 80px;
    font-family: "Poppins";
}