.taskMainContainer {
  height: calc(100% - 125px);
  width: 100%;
  flex-grow: 1;
  display: flex;
}
.addTask {
  width: 100%;
  contain: content;

  /* border: 1px solid black; */
}
.taskDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 1px solid black; */
  background: #f4fbfc;
  border-radius: 5px;
}
.headertask {
  top: 0;
  position: sticky;
  background-color: #f8f8f8f3; /* Add your desired background color */
  z-index: 99;
  display: flex;
  background: #f8f8f8f3;
  padding: 12px 12px 12px 35px;
  justify-content: center;
}
.taskDetailsHeader {
  display: flex;
  align-items: center;
  height: 6%;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}
.taskDetailsBody {
  height: 94%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taskDetailsHeadText {
  color: var(--primary);
  font-weight: 500;
}
.addTaskCompHolder {
  display: flex;
  height: 4rem;
  align-items: center;
}
.addTaskIconHolder {
  background: #f4fbfc;
  color: var(--primary);
  margin-left: 40px;
  height: 27px;
  width: 28px;
  border-radius: 5px;
}
.addTaskIcon {
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9f3f4;
  border-radius: 5px;
}
.allTaskContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.createdTaskCompContainer {
  height: 5rem;
  /* border: 1px solid black; */
  padding: 10px;
}
.highlightedTaskComponent {
  background-color: lightblue;
}

.taskComponent {
  display: flex;
  min-height: 70px;
  align-items: center;
  padding: 0px 12px 0px 8px;
  justify-content: center;
  position: relative;
}
.subTas {
  padding-left: 20px;
}
.addSubTaskContainer {
  margin: 12px 0 0px 38px;
  padding: 0 0 14px 0;
}
.allTaskContInside {
  box-shadow: 0px 3px 10px rgb(0 0 0 / 6%);
  position: relative;
}
.taskComponent:hover {
  transition: 0.5s;
}
.taskText {
  font-size: 13px;
  font-weight: 500;
}

.subTaskContainer {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 1s;
}

.subTaskContainer.visible {
  opacity: 1;
  visibility: visible;
}
.bulletButtonClass {
  border: 1px solid var(--primary);
  border-radius: 100%;
  height: 20px;
  aspect-ratio: 1;
  padding: 3px;
  display: flex;
  align-items: center;
}
.selectedBullet {
  background: var(--primary);
  height: 100%;
  border-radius: 100%;
}
.delayedTextHolder {
  color: #fb7158;
  font-size: 11px;
}
.progressIconHolder {
  border-radius: 3px;
  font-size: 11px;
  max-width: 100px;
  color: white;
  padding: 3px;
  font-weight: 500;
  text-align: center;
}
.priorityHolder {
  border-radius: 3px;
  border: 1px solid #fb7158;
  font-size: 11px;
  max-width: 50px;
  color: #fb7158;
  padding: 1px 4px;
  font-weight: 500;
  text-align: center;
}
.subTaskProgressIcon {
  background: #d8f0f2;
  border: none;
}
.dropdownArrowContainer .active {
  transform: rotate(180deg);
}
.highlighted {
  background-color: red; /* or any desired color */
}
.dropdownArrowContainer {
  height: 20px;
  position: absolute;
  width: 21px;
  bottom: -15px;
  right: 49%;
}
.progressPercentHolder {
  font-size: 10px;
  font-weight: 600;
  padding-top: 2px;
}
.createSubTaskButton {
  color: #b69fb3;
  font-size: 12px;
  display: flex;
  padding: 4px 5px;
  border-radius: 4px;
  background-color: #f4eaf3;
  font-weight: 500;
  align-items: center;
  width: 12%;
  margin-top: 10px;
}
.subTaskComponent {
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  /* padding-top: 5px; */
}
.formContainer {
  height: 94%;
  width: 100%;
  display: flex;
  contain: content;
  overflow: auto;
}
.taskDetailHolder {
  width: 100%;
  padding: 10px;
  line-height: 52px;
  height: 100%;
  position: relative;
}
.createSubTaskClass {
  /* display: flex;
    align-items: center; */
  border-bottom: 1px dotted lightgrey;
  padding: 10px;
}
.subTaskInnerContainer {
  justify-content: space-around;
  display: flex;
  align-items: center;
  background: white;
  padding-left: 10px;
}
.subTaskInput {
  border: transparent;
  margin-left: 11px;
  width: 15rem;
}
.addSubtaskClass {
  margin-top: 10px;
}
.completeTaskText {
  text-decoration-line: line-through;
  text-decoration-color: var(--primary);
}
.updateStatusHolder {
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}
.taskTypeButton {
  display: flex;
  align-items: center;
  height: 3rem;
}
.taskDDMainContainer {
  position: absolute;
  top: 54px;
  left: 156px;
  background: #fff;
}
.taskDDContent:hover {
  color: var(--light-gray);
  background: #d8f0f2;
  transition: 0.2s;
}
.btnTeamTask {
  margin: 0px 6px;
  padding: 4px 7px;
  border-radius: 4px;
  display: flex;
  gap: 6px;
  align-items: center;
  background: white;
  color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: none;
}
.taskDDContent {
  color: var(--primary);
  transition: 0.2s;
  display: flex;
  padding: 6px;
  border-bottom: 1px solid var(--grey);
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.taskCont{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 12px;
}
.taskActiveClass {
  color: var(--primary);
  background: var(--primary-hover);
}
.tasksBadge {
  height: 21px;
  display: flex;
  align-items: center;
  position: inherit;
  width: 20px;
}
.tasksButton {
  display: flex;
  align-items: center;
  padding: 6px;
}
.moreActivityBadge {
  top: 13px;
  right: -22px;
}
.editTaskButton {
  margin-left: auto;
  margin-right: 10px;
}
.taskDetailContent {
  display: flex;
  align-items: center;
}
