.headerComponentContainer{
    width: 100%;
    height: 100%;
}

.headerComponentWrapper{
    height: 90px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    position: relative;
}
.headerComponentWrapper .topCornerIcon svg{
    position: absolute;
    top: 0;
    left: 0;
}
.headerComponentWrapper .bottomCornerIcon svg{
    position: absolute;
    bottom: 0;
    right: 0;
}
.headerComponentWrapper .topLineIcon img{
    position: absolute;
    top: 0;
    right: 20%;
    width: 150px;
}
.headerComponentWrapper .bottomLineIcon img{
    position: absolute;
    bottom: 0;
    right: 30%;
    width: 120px;
}
.headerComponentWrapper .dotIcon svg{
    position: absolute;
    bottom: 50px;
    right: 30px;
}
.headerComponentWrapper .propsMidIcon{
    position: absolute;
    bottom: -5px;
    left: 10%;
    width: 19%;
    box-shadow: none !important;
}
.headerComponentWrapper .propsMidTitle{
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: #1296B0;
}
.headerComponentWrapper .propsMidTitle h3{
    font-size: 16px;
}





