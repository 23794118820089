.moreActivityContainer {
  position: relative;
}
.moreActivityHolder {
    border-radius: 6px;
    position: absolute;
    background-color: white;
    top: 43px;
    z-index: 2;
    left: 0px;
    box-shadow: 5px 6px 15px rgb(0 0 0 / 5%);
    border: 1px solid #1296b038;
}
.activityBadge{
    background-color: red;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activityBadge>label{
    margin: 0 12px;
    font-weight: bold;
}
.activityTile{
    padding: 12px;
    display: flex;
    gap: 6px;
    align-items: center;
}
.activityTileActive{
    background-color: var(--primary-hover);
}
.activityTile:hover{
    background-color: var(--global-hover);
}
.moreActivityLabelContainer{
    display: flex;
    align-items: center;
    height: fit-content;
    padding-right: 8px;
    border-radius: 6px;
}
.moreActivityLabelContainer:hover{
    background-color: var(--global-hover);
}
.moreActivityLabelHolder{
    height: 42px;
    display: flex;
    align-items: center;
}