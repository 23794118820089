.greetingsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.greetingsTab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.birthdayCardContainer {
  flex-grow: 1;
  display: block;
  margin-left: -12px;
  margin-right: -12px;
  height: 100%;
}

.dayUpdate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1296b0;
  margin-left: 37px;
  text-decoration: underline var(--primary) 2px;
}

.greetingsUpdateHorizontalLine {
  width: 6.5%;
  margin-left: 37px;
  margin-top: 0px;
  border: 0.595365px solid #1296b0;
  height: 0px;
}

.cardDisplayContainer {
  display: flex;
  flex-flow: row wrap;
}

.engageMainContentContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

.greetingEngageHeaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3% 0 4%;
  margin-bottom: 20px;
}

.greetingEngageHeaderContainer img {
  width: 75%;
  border-radius: 5px;
}

.greetingsImgContainer {
  padding: 20px;
}

.greetingsImgWrapper {
  position: relative;
}

.greetingsBirthdayContent img {
  position: absolute;
  width: 26%;
  top: 24%;
  left: 11.5%;
  border-radius: 100%;
}

.greetingsBirthdayContent h4 {
  position: absolute;
  color: var(--PMS, #fb7158);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 39.278px;
  left: 47%;
  top: 38%;
}

@media screen and (max-width: 1100px) {
  .greetingsBirthdayContent h4 {
    font-size: 22px;
  }
}

.greetingsText {
  color: #555;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.11px;
  margin-top: 20px;
}

.postBodyTextContainer span {
  cursor: pointer;
  color: #1296b0;
  font-weight: 500;
}

.postBodyTextContainer .socialPostSharedContent {
  cursor: pointer;
  color: #1296b0;
  font-weight: 500;
  font-size: 13px;
}

.greetingsMarriageContent h4 {
  position: absolute;
  color: #7a80d1;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  left: 47%;
  top: 42%;
}

@media screen and (max-width: 1100px) {
  .greetingsMarriageContent h4 {
    font-size: 20px;
  }
}

.greetingsMarriageSharedContent h4 {
  position: absolute;
  color: #7a80d1;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  left: 267px;
  top: 120px;
}

.greetingsWorkContent img {
  width: 27%;
  border-radius: 15px;
  border: 1px solid whitesmoke;
  position: absolute;
  top: 12%;
  right: 9%;
  height: 52%;
}

.greetingsWorkContentText {
  position: absolute;
  text-align: center;
  bottom: 7%;
  right: 5%;
}

.greetingsWorkContentText h4 {
  color: #71a5de;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media screen and (max-width: 1100px) {
  .greetingsWorkContentText h4 {
    font-size: 16px;
  }
}

.greetingsWorkContentText p {
  color: #fb7158;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.greetingsNewJoiningContent img {
  position: absolute;
  left: 7.4%;
  top: 23%;
  width: 27%;
  border-radius: 100%;
}

.greetingsNewJoiningContentText {
  position: absolute;
  bottom: 13%;
  left: 54%;
}

.greetingsNewJoiningContentText h4 {
  color: #84a59d;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 1300px) {
  .greetingsNewJoiningContentText h4 {
    font-size: 16px;
  }
}

.greetingsNewJoiningContentText p {
  color: #84a59d;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.galleryTextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px 15px 25px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}

/* .galleryImageWrapper1 {
  width: 100%;
  height: 375px;
  display: flex;
  justify-content: center;
}
.galleryImageWrapper1 img {
  width: 100%;
  height: auto;
  object-fit: contain;
} */

.galleryTextContainer span {
  color: #bfbfbf;
}

.galleryTextContainer h6 {
  color: #1296b0;
  font-size: 16px;
  text-transform: capitalize;
}

.galleryImageContainer {
  padding: 15px 30px 30px 30px;
}

.galleryBottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.galleryBottomContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #bfbfbf;
  cursor: pointer;
}

.galleryBottomContainer span {
  margin-left: 5px;
}

.galleryBodyImgContainer2 img,
.galleryBodyImgContainer3 img,
.galleryBodyImgContainer4 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.galleryBodyImgContainer2 {
  height: 300px;
  display: grid;
  grid-template-columns: 290px 290px;
  grid-template-rows: auto;
  grid-gap: 3px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.galleryBodyImgContainer3 {
  height: 337px;
  display: grid;
  grid-template-columns: 300px 280px;
  grid-template-rows: auto;
  grid-gap: 3px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.galleryBodyImgContainer3 .subImage2b img {
  height: 166px;
}

.galleryBodyImgContainer4 {
  height: 341px;
  display: grid;
  grid-template-columns: 300px 280px;
  grid-template-rows: auto;
  grid-gap: 3px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.galleryBodyImgContainer4 .subImage2b img {
  height: 110px;
}

.galleryImgHideMainBox {
  height: 110px;
  width: 277px;
  position: absolute;
  background-color: #00000080;
  right: 1px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryDescriptionContainer {
  padding: 15px 25px;
}

.greetingTagAndHeaderContainer {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}

.greetingsNamedSpan {
  cursor: pointer;
  color: #1296b0;
  font-weight: 500;
  margin-bottom: 15px !important;
}

.greetingsNamedSpan:hover {
  text-decoration: underline;
}

.greetingsPostMainTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}