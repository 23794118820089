/* Premium Content Page Styles */
.premium-content-page {
    display: flex;
    margin: 12% auto;
    justify-content: center;
    align-items: center;
  }
  
  .premium-content-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
    max-width: 600px;
  }
  
  .premium-content-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .premium-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .premium-content-heading {
    font-size: 28px;
    color: #333;
  }
  
  .premium-content-description {
    font-size: 18px;
    color: #666;
    margin-top: 20px;
  }
  
  .upgrade-linkSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: var(--secondary);
    color: #fff;
    font-weight: bold;
    text-align: start;
    padding: 6px 8px;
    width: fit-content;
    border-radius: 3.007px;
    background: #ffc329;
    font-size: 20px;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .upgrade-link:hover {
    background-color: #ff4400;
  }
  .prPas {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    max-width: 430px;
  }
  .headPre {
    color: var(--Social, #82c1ad);
    font-family: Poppins;
    font-size: 39px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.21px; /* 39.472% */
  }
  