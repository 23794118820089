.LeaveBalanceProgressBarTop {
  display: flex;
  justify-content: space-evenly;
}
.ProgressTextTop {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  font-size: 13px;
  margin-top: 86px;
  width: 95%;
  margin-left: 24px;
}
.BalanceProgressHolder {
  width: 80px;
  height: 80px;
}
.BessHolder {
  width: 40px;
  height: 40px;
}
.LeaveBalanceProgressBarBottom {
  display: flex;
  width: 83%;
  margin: auto;
  margin-top: 7%;
  justify-content: space-evenly;
}
.ProgressBars {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding-top: 12px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
  overflow-x: hidden;
}
.Text3 {
  margin-right: 6px;
  color: #5bc4bf;
  font-weight: 300;
}
.Text1 {
  color: #5991c2;
  font-weight: 300;
}
.Text2 {
  color: #da5db6;
  font-weight: 300;
}
.Text4 {
  color: #fb7158;
  font-weight: 300;
}
.Text5 {
  color: #8a88ed;
  font-weight: 300;
}
.ProgressTextBottom {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  font-size: 13px;
  margin-top: 86px;
  width: 76%;
  margin-left: 30px;
}
.headingWithDropdown {
  padding: 0 12px;
  height: 51px;
  z-index: 2;
}
