.masterSideMenuContainer {
    box-shadow: none;
    border-radius: 0;
}

.masterSideMenuContainer>.menuOptions {
    border-radius: 6px;
}

.whiteBackground {
    background-color: #fff;
}

.timeTableDisplay {
    width: 100%;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0px 6px;
    flex-grow: 1;
    overflow: auto;
}

.timeTableDisplay td {
    padding: 5px 10px;
}