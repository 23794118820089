.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.subTabActive {
  color: var(--secondary);
}

.subContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.subContainerHeading {
  color: var(--secondary);
  width: 90%;
  font-weight: 500;
}

.contentHolder {
  background-color: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: relative;
  width: 90%;
  padding: 1rem;
}

.orgTeamHolder {
  background: #FFFFFF;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 90%;
  padding: 1rem;
  gap: 1rem;
}

.contentBackground {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.orgTeamData {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 30px;
  gap: 20px;
  background-color: var(--primary-hover);
  border-radius: 40px;
  color: var(--primary);
}