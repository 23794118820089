.notificationsitem {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 6px;
  background-image: url(../../../Assets/NotificationsBackground.svg);
  background-repeat: no-repeat;
  margin-top: 18px;
  background-size: contain;
}
.notificationsitem:hover {
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.notificationDataContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  flex-grow: 1;
}

.notificationsItemImage {
  width: 58px;
  height: 57px;
  border-radius: 100%;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 5%);
  border: 1px solid white;
  flex-shrink: 0;
  margin: 6px;
}

.notificationsItemImage > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.notificationsItemUpdate {
  width: 100%;
  display: flex;
}

.notificationsItemHeading {
  font-weight: 400;
  font-size: 15px;
}

.notificationsItemcontent {
  color: #555555;
  font-size: 16px;
  padding: 8px 0px;
  font-weight: 400;
  opacity: 0.8;
}
.notificationsItemcontent a{
  color: #1296b0;
  text-decoration: none;
}

.notificationsItemtime {
  background-color: #d8f0f2;
  border-radius: 4px;
  width: 3rem;
  display: flex;
  justify-content: center;
  color: var(--primary);
  font-size: 12px;
}

.notificationsItemdatetime {
  color: #bfbfbf;
  font-size: 12px;
}
.dataContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
