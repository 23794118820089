.criteriaCreateStyle {
    width: 100%;
    height: 48px;
    padding: 0px 26px 0px 26px;
    background-color: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 6px;
    box-shadow: 0px 2px 6px 0px #0000000F;

}


.headingCriteriaCreate {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #1296B0;
    /* margin: 19px 16px; */
    margin: 0px 2px 19px 2px;
    padding: 10px 0px;
    border-bottom: 1px solid #E6E6E6;

}

.AppraisalWeightageTable>table,
.AppraisalWeightageTable td {
    border: 1px solid #E6E6E6;
    border-collapse: collapse;
}

/* .AppraisalWeightageTable td {
    padding: 2px;
} */
.pmsactionconfirm {
    position: absolute;
    left: 12px;
    top: 11px;
    z-index: 16;
    background-color: white;
    width: calc(100% - 24px);
    height: calc(100% - 22px);
    border-radius: 8px;
    box-shadow: 0 3px 10px #0000000f;
}

.tableHeaderHEad {
    box-shadow: 0px 1px 3px 0px #0000000A;
    width: 100%;
    height: 44.07px;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
}

.DepartmentDesign {
    width: 100%;
    border: 1px solid #E9F3F4;
    border-radius: 12px;
    margin: 20px 0px;
    padding: 19px 0px;

}

.PmsDetailsTable table {
    border-spacing: 0;
}

.PmsDetailsTable thead {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    /* text-transform: uppercase; */
}

.PmsDetailsTable tbody {
    font-size: 14px;
}

.PmsDetailsTable th,
.PmsDetailsTable td {
    padding: 18px 24px;
    white-space: nowrap;
    /* border-bottom: 1px solid black; */
    border: none;
    font-weight: 500;
}

/* .PmsDetailsTable tr:last-child td {
    border-bottom: none;
} */

.PmsDetailsTable tr.sticky {
    position: sticky;
    top: 0;
    /* background: white; */
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); */
}

.PmsDetailsTable th.sticky,
.PmsDetailsTable td.sticky {
    position: sticky;
    left: 0;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.sticky2,
.PmsDetailsTable td.sticky2 {
    position: sticky;
    left: 90px;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.sticky3,
.PmsDetailsTable td.sticky3 {
    position: sticky;
    left: 223px;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.sticky4,
.PmsDetailsTable td.sticky4 {
    position: sticky;
    left: 367px;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.sticky5,
.PmsDetailsTable td.sticky5 {
    position: sticky;
    left: 497px;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.sticky6,
.PmsDetailsTable td.sticky6 {
    position: sticky;
    left: 630px;
    background: white;
    z-index: 10;
}

.PmsDetailsTable th.normalCell,
.PmsDetailsTable td.normalCell {
    position: relative;
    background: white;
}



.PmsDetailsTable th.sticky::before,
.PmsDetailsTable td.sticky::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}


.PmsDetailsTable th.sticky2::before,
.PmsDetailsTable td.sticky2::before {

    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsDetailsTable th.sticky3::before,
.PmsDetailsTable td.sticky3::before {

    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsDetailsTable th.sticky4::before,
.PmsDetailsTable td.sticky4::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsDetailsTable th.sticky5::before,
.PmsDetailsTable td.sticky5::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsDetailsTable th.sticky6::before,
.PmsDetailsTable td.sticky6::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsDetailsTable th.normalCell::before,
.PmsDetailsTable td.normalCell::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border-right: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
}

.PmsClassificationTable table {
    border-spacing: 0;
}

.PmsClassificationTable thead {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    /* text-transform: uppercase; */
}

.PmsClassificationTable tbody {
    font-size: 14px;
}

.PmsClassificationTable th,
.PmsClassificationTable td {
    padding: 18px 24px;
    white-space: nowrap;
    /* border-bottom: 1px solid black; */
    border: none;
}

/* .PmsClassificationTable tr:last-child td {
    border-bottom: none;
} */

.PmsClassificationTable tr.sticky {
    position: sticky;
    top: 0;
    /* background: white; */
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); */
}

.PmsClassificationTable th.sticky,
.PmsClassificationTable td.sticky {
    position: sticky;
    left: 0;
    background: white;
    z-index: 10;
}

.PmsClassificationTable th.sticky2,
.PmsClassificationTable td.sticky2 {
    position: sticky;
    left: 92px;
    background: white;
    z-index: 10;
}

.PmsClassificationTable th.sticky3,
.PmsClassificationTable td.sticky3 {
    position: sticky;
    left: 233px;
    background: white;
    z-index: 10;
}

.PmsClassificationTable th.sticky4,
.PmsClassificationTable td.sticky4 {
    position: sticky;
    left: 387px;
    background: white;
    z-index: 10;
}



.PmsClassificationTable th.normalCell,
.PmsClassificationTable td.normalCell {
    position: relative;
    background: white;
}



.PmsClassificationTable th.sticky::before,
.PmsClassificationTable td.sticky::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}


.PmsClassificationTable th.sticky2::before,
.PmsClassificationTable td.sticky2::before {

    content: "";
    position: absolute;
    left: 1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsClassificationTable th.sticky3::before,
.PmsClassificationTable td.sticky3::before {

    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsClassificationTable th.sticky4::before,
.PmsClassificationTable td.sticky4::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsClassificationTable th.sticky5::before,
.PmsClassificationTable td.sticky5::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsClassificationTable th.sticky6::before,
.PmsClassificationTable td.sticky6::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsClassificationTable th.normalCell::before,
.PmsClassificationTable td.normalCell::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border-right: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable table {
    border-spacing: 0;
}

.PmsAppraisalSlabTable thead {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    /* text-transform: uppercase; */
}

.PmsAppraisalSlabTable tbody {
    font-size: 14px;
}

.PmsAppraisalSlabTable th,
.PmsAppraisalSlabTable td {
    padding: 18px 24px;
    white-space: nowrap;
    /* border-bottom: 1px solid black; */
    border: none;
}

/* .PmsAppraisalSlabTable tr:last-child td {
    border-bottom: none;
} */

.PmsAppraisalSlabTable tr.sticky {
    position: sticky;
    top: 0;
    /* background: white; */
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); */
}

.PmsAppraisalSlabTable th.sticky,
.PmsAppraisalSlabTable td.sticky {
    position: sticky;
    left: 0;
    background: white;
    z-index: 10;
}

.PmsAppraisalSlabTable th.sticky2,
.PmsAppraisalSlabTable td.sticky2 {
    position: sticky;
    left: 90px;
    background: white;
    z-index: 10;
}

.PmsAppraisalSlabTable th.sticky3,
.PmsAppraisalSlabTable td.sticky3 {
    position: sticky;
    left: 231px;
    background: white;
    z-index: 10;
}

.PmsAppraisalSlabTable th.sticky4,
.PmsAppraisalSlabTable td.sticky4 {
    position: sticky;
    left: 387px;
    background: white;
    z-index: 10;
}



.PmsAppraisalSlabTable th.normalCell,
.PmsAppraisalSlabTable td.normalCell {
    position: relative;
    background: white;
}



.PmsAppraisalSlabTable th.sticky::before,
.PmsAppraisalSlabTable td.sticky::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}


.PmsAppraisalSlabTable th.sticky2::before,
.PmsAppraisalSlabTable td.sticky2::before {

    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable th.sticky3::before,
.PmsAppraisalSlabTable td.sticky3::before {

    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable th.sticky4::before,
.PmsAppraisalSlabTable td.sticky4::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable th.sticky5::before,
.PmsAppraisalSlabTable td.sticky5::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable th.sticky6::before,
.PmsAppraisalSlabTable td.sticky6::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.PmsAppraisalSlabTable th.normalCell::before,
.PmsAppraisalSlabTable td.normalCell::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    width: 100%;
    border-right: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
}

thead>td {
    font-weight: 500;
}