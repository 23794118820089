.myProfileDetailsMainContainer {
  width: 100%;
  border-right: 1px solid #d8f0f2;
  padding-bottom: 100px;
}

.myProfileHeaderWrapper {
  padding-left: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.myProfileHeaderContainer {
  width: 100%;
  height: 72px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}

.myProfileHeaderContainer svg {
  width: 20px;
  cursor: pointer;
}

.myProfPage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.myProfileHeaderContainer span {
  color: #1296b0;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-left: 6px;
}

.myProfileDetailsMainWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1 !important;
}

.myProfileDetailsMainWrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.myProfileImageContainer {
  width: 100%;
  border-bottom: 1px solid #d8f0f2;
  padding-bottom: 20px;
}

.myProfileImageWrapper {
  display: flex;
  justify-content: center;
  padding: 30px 0 5px 0;
}

.myProfileImageContainer .myProfileImageWrapper svg {
  mix-blend-mode: darken;
  position: relative;
  right: -75px;
}

.myProfileImage {
  /* background: url("https://s3-alpha-sig.figma.com/img/0fb0/fd7d/8b98e333d42d8c73690ec95f026bb8e8?Expires=1691971200&Signature=ilYN7BKBM7TaVr9RxFGsL6pecj5D-GZ7mIOoPtTwnb0UVrsOV5ao9uKn2oKp-4ecYKd6sDYs0lSy1kSH87G2gLzAzb~tah1FOhhr5io-DHUMsbg-Y0EHAorTIaj7a3kzOUmVHXe6NEpntOvpGGr8hKY7EqhXD8LfIFQr5YCzYVsztCCaGh1WP-j-2YgkAjjt5qw~VmbwlDU7iMUDBT79EovfgIn5zqksGLIQj7i4IBSHaYkPczbH9KDvYV6e6E4R332z1ySk65olI3itkngscoVi8TQRh6hvVxUUZQ8KLMsFyhduT~ru9tBxewW1R85Z-9PcmZMuxihcuuxJDiKtUQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 102.879px;
  height: 102.879px;
  border-radius: 1000px;
  position: relative;
  top: 25px;
  left: -55px;
  /* z-index: -1; */
}

.cameraIcon {
  position: relative;
  top: 100px;
  right: 150px;
}

.imgText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imgText h4 {
  font-size: 14px;
  font-weight: 500;
  color: #1296b0;
}

.imgText span {
  font-size: 11px;
  font-weight: 500;
}

.myProfileStatusContainer {
  padding: 25px 30px;
}

.myProfileStatusContainer p {
  font-size: 12px;
  color: #a1a1a1;
  margin-bottom: 10px;
}

.myProfileStatusContainer .busyStatusProfileWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff4e5;
  padding: 7px 15px;
  border-radius: 5.5px;
  margin-bottom: 15px;
}

.myProfileStatusContainer .dndStatusProfileWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 230, 230);
  padding: 7px 15px;
  border-radius: 5.5px;
  padding-bottom: 10px;
}

.customMUISwitch .MuiSwitch-thumb {
  height: 10px !important;
  width: 10px !important;
  color: #555;
}

.customMUISwitch .MuiSwitch-track {
  height: 6px;
  width: 16px;
  background-color: #bfbfbf;
  opacity: 1;
}

.customMUISwitch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(14px) !important;
}

.myProfileStatusContainer .busyStatus,
.myProfileStatusContainer .dndStatus {
  display: flex;
  align-items: center;
}

.myProfileStatusContainer .busyStatus span,
.myProfileStatusContainer .dndStatus span {
  padding-left: 10px;
  font-size: 0.9rem;
}

.myProfileStatusContainer .busyStatus svg,
.myProfileStatusContainer .dndStatus svg {
  margin-top: 5px;
}

.myProfileStatusContainer .busyStatus .busyStatusDot {
  background-color: #f7941d;
  border-radius: 100%;
  position: relative;
  height: 10px;
  width: 10px;
  left: 0;
}

.myProfileStatusContainer .dndStatus .dndStatusDot {
  background-color: #f71d1d;
  border-radius: 100%;
  position: relative;
  height: 10px;
  width: 10px;
  left: 0;
}

.myProfileStatusContainer .changeStatus Switch {
  background-color: black;
  width: 19px;
  height: 10px;
}

.myProfileAboutContainer {
  padding: 0px 30px 10px 30px;
}

.myProfileAboutContainer .myProfileAboutWrapper {
  border: 1px solid #d8f0f2;
  border-radius: 5px;
  padding: 15px;
}

.myProfileAboutContainer .myProfileAboutWrapper .aboutTextWrapper1,
.myProfileAboutContainer .myProfileAboutWrapper .aboutTextWrapper2,
.myProfileAboutContainer .myProfileAboutWrapper .aboutTextWrapper3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: flex-start;
  padding: 10px 0;
}


.myProfileGroupContainer {
  padding: 20px 30px 0px 30px;
}

.myProfileGroupContainer p {
  padding-bottom: 7px;
}

.myProfileGroupContainer .myProfileGroupWrapper {
  border: 1px solid #d8f0f2;
  padding: 10px;
  padding-bottom: 30px;
}

.myProfileGroupContainer .myProfileGroupWrapper .creteNewGroup {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.myProfileGroupContainer .myProfileGroupWrapper .creteNewGroup p,
.myProfileGroupContainer .myProfileGroupWrapper .creteNewGroup svg {
  cursor: pointer;
}

.langHead {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin: 12px;
  padding: 12px 5px 0px 5px;

}

.creteNewGroup {
  background-color: rgba(216, 240, 242, 0.4);
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.creteNewGroup p {
  color: #000;
  font-size: 13px;
  padding-left: 10px;
  padding-bottom: 0;
}

.goupList {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.goupList .imageContainer {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.goupList .imageContainer img {
  width: 100%;
  height: 100%;
}

.goupList .textContainer h3 {
  color: #000;
  font-size: 13px;
}

.goupList .textContainer p {
  font-size: 11px;
  color: #000;
  padding-bottom: 0;
}

.myProfileBlockContainer {
  padding: 30px 40px;
  padding-bottom: 100px;
}

.myProfileBlockContainer .myProfileBlockWrapper {
  display: flex;
  align-items: center;
}

.myProfileBlockContainer .myProfileBlockWrapper .blockText {
  font-size: 14px;
  color: #f71d1d;
  font-weight: 700;
  padding-left: 10px;
  margin-bottom: 4px;
}