.certificateBackground {
  margin: auto;
  /* border: 2px solid black; */
  height: 44rem;
  width: 61rem;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.certificate-notice {
  color: #333;
  /* Dark text for readability */
  background-color: #f8f8f8;
  /* Light background for subtle emphasis */
  border-left: 4px solid var(--PeopleSOl-Primery, #1296B0);
  /* A touch of color to draw attention */
  padding: 16px;
  /* Spacing around the text */
  margin: 20px 0;
  /* Space above and below the notice */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  max-width: 600px;
  /* Maximum width of the notice */
  margin-left: auto;
  /* Centering the notice */
  margin-right: auto;
  font-size: 12px;
  /* Centering the notice */
}

.certificateHolder {
  background-image: url(../../../../src/Assets/CertificateImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 41rem;
  width: 58rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.certiView {
  max-width: 100%;
  width: fit-content;
}

.certificateBody {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 23rem;
  top: 20rem;
}

.certificateHeading {
  position: absolute;
  left: 23rem;
  top: 9rem;
  width: 26rem;
  color: var(--primary);
  display: flex;
  flex-direction: column;
}

.certificateHeadingBorder {
  height: 3px;
  background-color: #75d6e9;
  border-radius: 100%;
  margin-top: 6px;
}

.certificateHeadingText {
  font-family: emoji;
  font-size: 43px;
  color: var(--primary);
}

.fillerText {
  font-family: revert;
  font-weight: 500;
  font-size: 16px;
  color: black;
}

.courseText {
  color: var(--primary);
  font-size: 16px;
  width: 25rem;
  font-weight: 600;
}

.signatureHolder {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 2rem;
  left: 23rem;
  width: 28rem;
}

.certificateSignature {
  border-top: 2px groove black;
  width: 10rem;
  display: flex;
  justify-content: center;
}

.signatureOwner {
  font-family: revert;
  font-weight: 500;
  color: black;
  font-size: 12px;
  padding-top: 7px;
}

.certificatePageContainer {
  width: 600px;
  height: 800px;
}