.learningVideoMainContainer {
	display: flex;
	height: 100%;
	width: 95%;
	justify-content: space-between;
}

.videoContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.videoCardLD {
	width: 100%;
	height: 584px;
	padding: 18px;
	display: flex;
	align-items: center;
	position: relative;
}

.videoDetailsHolder {
	/* height: 15rem; */
	/* border: 2px solid; */
}

.videoDetailsContainer {
	width: 28rem;
	/* border: 2px solid; */
}

.videoDescriptionContainer {
	height: 50px;
	display: flex;
	justify-content: space-between;
	/* padding: 0 12px; */
	padding: 0 21px;
}

.descriptionHolder {
	width: 70%;
	display: flex;
	flex-direction: column;
}

.viewsHolder {
	display: flex;
}

.inputCommentHolder {
	height: 4rem;
	position: relative;
}

.sendSvg {
	position: absolute;
	right: 41px;
	border: none;
	top: 9px;
	background-color: white;
	border-radius: 50px;
}

.commentInput {
	margin-left: 11px;
	width: 97.5%;
	height: 47px;
}

.commentContentHolder {
	display: flex;
	flex-direction: column;
}

.commentTime {
	color: #bfbfbf;
	font-size: 12px;
	align-self: center;
}

.commentOwner {
	color: var(--people-s-ol-primery, #1296b0);
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	letter-spacing: 0.24px;
	font-size: 12px;
	color: #555555;
}

.commentText {
	font-weight: 500;
	font-size: 15px;
	color: #555555;
	text-wrap: wrap;
}

.commentHolder {
	/* height: fit-content; */
	overflow-y: auto;
	height: 166px;
}

.learningDetailsContainer {
	width: 30%;
	min-width: 440px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background: #FFF;
	box-shadow: 0px 1.89445px 14.20839px 0px rgba(0, 0, 0, 0.06);
	height: 740px;
	margin: 10px 5px 0px 5px;
}

.mainHeading {
	color: var(--primary);
	font-size: 18px;
	font-weight: 500;
}

.learningHeadingContainer {
	width: 92px;
	display: flex;
	justify-content: space-between;
	padding: 14px 14px;
	margin: 0px 0px 16px 0px;
	border-radius: 4px 4px 0px 0px;
	background: #FFF;
	box-shadow: 0px 0.69005px 4.14028px 0px rgba(0, 0, 0, 0.05);
}

.learningHeadingActive {
	width: 92px;
	color: var(--White, #FFF);
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	padding: 0px 0px 10px;
}

.learningHeading {
	width: 92px;
	color: var(--PeopleSOl-Primery, #1296B0);
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	padding: 0px 0px 10px;
}

.learningHeadingCompleted {
	width: 92px;
	color: var(--PeopleSOl-Primery, #1296B0);
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	margin: 0px 0px 10px;
}

.certificateButton {
	border: 2px solid var(--primary);
	border-radius: 3px;
	width: 84px;
	color: var(--primary);
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.certificateButtonActive {
	background-color: var(--primary);
	color: white;
	font-weight: 400;
	align-items: center;
}

.mainHeadingDeactive {
	color: #d9d9d9;
}

.learningBodyDetailsContent {
	display: flex;
}

.learningBodyDetails {
	width: 100%;
}

.learningDetailsBody {
	width: 100%;
	overflow: hidden;
}

.tabContainerld {
	z-index: 1;
	display: flex;
	justify-content: space-around;
	position: relative;
	align-self: center;
	width: 100%;
}

.learningDetailsCompContainer {
	display: flex;
	flex-direction: column;
	/* background-color: rgb(228 247 249); */
	height: 70px;
	width: 100%;
	padding: 10px 10px 10px 19px;
	min-width: 430px;
	overflow: hidden;
	border-bottom: 1px solid rgba(91, 196, 191, 0.5);
	border-top: 1px solid rgba(91, 196, 191, 0.5);
}

.certificateImage {
	width: 5rem;
	border-radius: 0;
}

.detailsListComp {
	display: flex;
	align-items: center;
	/* padding-top: 10px; */
	height: 6rem;
	background: var(--Pastel, #D8F0F2);
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.02);
}

.detailsListCompActive {
	background-color: rgb(228, 247, 249);
}

.completeIconHolder {
	width: 4rem;
	display: flex;
	justify-content: center;
}

.learningDetailsDDContainer {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 8px;
	background: #FFF;
	width: 93%;
	margin: 0px auto 14px;

}

.learningDetailsDDContainerActive {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 8px;
	background: var(--PeopleSOl-Primery, #1296B0);
	width: 93%;
	margin: 0px auto 14px;

}

.learningDetailsDDContainerCompleted {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-radius: 8px;
	background: #D3EDF0;
	width: 93%;
	margin: 0px auto 14px;

}

.dropdownIconHolder {
	width: 2rem;
	position: absolute;
	right: -7px;
	top: -22px;
}

.certificateList {
	position: absolute;
	z-index: 3;
	background-color: white;
	height: 51rem;
	width: 100%;
	overflow-y: auto;
}

.closeIconImage {
	position: absolute;
	left: -170px;
	bottom: 292px;
	height: 21px;
}

.downloadIconImage {
	position: absolute;
	left: -198px;
	bottom: 287px;
	height: 30px;
}

.dotSeparator {
	margin: 8px 6px;
	font-size: 14px;
	background-color: #555555;
	border-radius: 100%;
	height: 4px;
	aspect-ratio: 1;
	display: flex;
}

.showVideoPlayer {
	display: block;
}

.hideVideoPlayer {
	display: none;
}

.popup-questionbox {
	position: absolute;
	background: #00000050;
	width: 97%;
	height: 94%;
	top: 18px;
	display: flex;
	align-items: center;
	left: 17px;
	justify-content: center;
	z-index: 999;
}

.popup-questionboxFull {
	position: absolute;
	background: #00000050;
	width: 100%;
	height: 100%;
	top: 0px;
	display: flex;
	align-items: center;
	left: 0px;
	justify-content: center;
	z-index: 999;
}

.questionbox {
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	/* contain: content; */
	position: relative;
	/* min-width: 700px; */
	height: -moz-fit-content;
	/* height: fit-content; */
	/* max-height: 70vh; */
	background: #fff;
	border-radius: 8px;
	border: 1px solid #999;
	/* max-height: 80%; */
	border-radius: 6px;
	background: #faf9f8;
	height: 100%;
	width: 100%;
}

.createHead {
	background-color: white;
	width: 100%;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	background-color: white;
	border-radius: 6px;
}

.createHead>label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: #1296b0;
}

.close-icon {
	cursor: pointer;
	height: 17px;
}

.popApproveLabel {
	display: flex;
	flex-direction: column;
	padding: 8px 22px;
}

.smallHeightPopup {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.popupContentHolderQuestion {
	padding: 12px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	justify-content: start;
}

.questionpopupstyle {
	overflow: auto;
	min-height: 87%;
	width: 100%;
	padding: 20px;
}

.popupdisappear {
	display: none;
}

.Onclickdisappear {
	display: none !important;
}

.popupappear {
	display: flex;
}

.reportsheading {
	background-color: white;
	/* width: 100%; */
	padding: 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	background-color: white;
	border-radius: 8px 8px 0px 0px;
}

.tableContainerta {
	display: flex;
	/* overflow: auto; */

	/* height: calc(100% - 57px); */
	position: relative;
	z-index: 0;
	/* height: calc(100% - 0px); */
	/* height: 100%; */
}

.tableContainerta>table {
	width: 100%;
}

.tableScrollContainerta {
	overflow-y: auto;
	overflow-x: auto;
	height: calc(100% - 57px);
}

/* .tableScrollContainertareports {
    overflow-y: auto;
    height: 713px;
} */
.tableFooterContainerta {
	height: 45px;
	min-height: 45px;
	z-index: 0;
	background-color: white;
	display: flex;
	align-items: center;
	margin: 0 12px;
	color: var(--secondary-text);
	gap: 6px;
	flex-shrink: 0;
	justify-content: space-between;
	margin: 5px 5px;
}

.contentTableHeight {
	height: calc(100% - 62px);
}

.rowstyle {
	background-color: #fbfbfb;
	border-bottom: 4px solid white;
	border-top: 4px solid white;
	height: 66px;
}

.fullTableContainerta {
	height: calc(100% - 48px);
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	flex-grow: 1;
}

.taheadingfont {
	color: var(--PeopleSOl-Primery, #1296b0);

	/* Hi */
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 115.464% */
	letter-spacing: 0.3px;
}

.headerTableStyle {
	height: 52px;
	background-color: #f4fbfc !important;
	position: sticky;
	top: 0px;
}

.headerTableStyle tr {
	height: 52px;
	background-color: #f4fbfc !important;
}

.headerTableStyle th {
	height: 52px;
	background-color: #f4fbfc !important;
	color: var(--PeopleSOl-Primery, #1296b0);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	/* 116.667% */
}

.rowstyle td {
	color: var(--PeopleSol-Dark-Grey, var(--black, #555)) !important;
	font-family: Poppins !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 18px !important;
	/* 150% */
	letter-spacing: 0.24px !important;
}

.scrollable-bodytd {}

.scrollable-bodyApprovalReport {}

.colorOptionsHeader {
	padding: 13px 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	height: "100px";
	/* justify-content: space-between; */
}

.colorOptionsHeaderContent {
	display: flex;
	align-items: center;
	width: 160px;
	justify-content: flex-start;
	margin: 10px;
}

.colorBoxta {
	height: 14px;
	width: 14px;
	margin-right: 10px;
}

.colorBoxtextta {
	color: var(--black, #555);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-wrap: nowrap;
	/* 145.455% */
}

.timeAttendenceHeader th:nth-child(1) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
	width: 80px;
}

.timeAttendenceBody td:nth-child(1) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
	width: 80px;
}

/*
.timeAttendenceHeader th:nth-child(2) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(3) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(4) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(5) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(6) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(7) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(8) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(9) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(10) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th:nth-child(11) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(12) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(13) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(14) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(15) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(16) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(17) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(18) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(19) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceHeader th:nth-child(20) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}


*/

/*
.timeAttendenceBody td:nth-child(2) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(3) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(4) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(5) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(6) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(7) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(8) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(9) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(10) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceBody td:nth-child(11) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(12) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(13) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(14) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(15) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(16) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(17) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(18) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(19) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}
.timeAttendenceBody td:nth-child(20) {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
} */

.timeAttendenceBody td {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.timeAttendenceHeader th {
	text-align: left;
	padding: 10px;
	text-wrap: nowrap;
}

.fontstyleDailyAttendance {
	color: var(--PeopleSOl-Primery, #1296b0);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
}

.presentColor {
	background-color: rgb(13, 181, 60);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.absentColor {
	background-color: red;
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.holidayColor {
	background-color: var(--pastel-5, #199eb8);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.casualleaveColor {
	background-color: var(--pastel-7, #8a88ed);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.sickleaveColor {
	background-color: var(--pastel-one, #c13699);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.earnedleaveColor {
	background-color: var(--pastel-2, #5991c2);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.compoffColor {
	background-color: var(--pastel-3, #5bc4bf);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.outdoorDutyColor {
	background-color: var(--pastel-4, #de64a1);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.weekOffColor {
	background-color: var(--pastel-6, #f04e30);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.halfDayLeaveColor {
	background-color: var(--Secondery, #f7941d);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.unpaidLeaveColor {
	background-color: var(--PeopleSOl-Primery, #1296b0);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.optionalLeaveColor {
	background-color: rgb(13, 181, 60);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.leaveColor {
	background-color: rgb(13, 181, 60);
	border-radius: 6px;
	padding: 2px 8px;
	color: white;
}

.candidateProfilePicHolderLD {
	height: 32rem;
	left: -98px;
}

.organisationparent {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	align-content: flex-start;
	justify-content: flex-start;
	/* overflow-x: hidden; */
	flex-grow: 1;
	height: calc(100% - 105px);
	position: relative;
	z-index: 0;
	padding: 10px 0px 0px 20px;
	margin-top: 14px;
}

.organisationBoxDesign {
	display: flex;
	border-top: 12px solid #d4f2f1;
	border-radius: 12px;
	/* width: 244px;
	height: 80px; */
	width: 386px;
	/* height: 113px; */
	background-color: #fff;
	filter: drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04));
	margin: 0px 20px 20px 0px;
	padding: 10px;
}

.organisationBoxDesign:hover {
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.learningProgressBox2:hover {
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background-color: #b69fb3;
	color: white;
}

.learningProgressBox2:hover .imgpurplearrow {
	display: none;
	opacity: 0;
	visibility: hidden;
}

.learningProgressBox2 {
	display: flex;
	border-radius: 8px;
	border: 1.5px solid var(--essmss-pastel, #f4eaf3);
	/* height: 60px; */
	height: 70px;
	width: 99px;
	align-items: center;
	margin-left: 10px;
	cursor: pointer;
}

.imgpurplearrow {
	display: block;
}

.imgwhitearrow {
	visibility: hidden;
	position: absolute;
	left: 0px;
	top: 0px;
}

.learningProgressBox2:hover .imgwhitearrow {
	visibility: visible;
	position: absolute;
	left: 0px;
	top: -5px;
}

.organisationBoxDesign>div {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.positonStyle {
	margin-top: 8px;
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 17.32px;
	margin-bottom: 10px;
	/* 157.451% */
}

/* .organisationBoxDesigntop {
	width: 100%;
	height: 5px;
	background-color: #D4F2F1;
} */
.positonStylenumber {
	color: var(--Office-Admin, #de64a1);
	text-align: center;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 123.712% */
}

.positonStyleskill {
	border-radius: 4px;
	/* background: var(--ra-pastel, #ececf5); */
	/* padding: 5px; */
	color: var(--reports-analytics, #a0a0cb);
	text-align: left;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	max-width: 140px;
}

.positonStyleskillspan {
	border-radius: 4px;
	background: var(--ra-pastel, #ececf5);
	padding: 10px;
	color: var(--reports-analytics, #a0a0cb);
	text-align: left;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	/* max-height: 45px; */
	text-align: "center";

	/* max-width: 140px; */
}

.skillsetheading {
	height: 76px;
	background-color: #d4f2f1;
	margin-left: 10px;
	border-radius: 8px;
	padding: 7px 10px 7px 10px;
}

.tableHeaderContainerskillset {
	z-index: 5;
	background-color: var(--white);
	padding: 0 32px 0 12px;
	margin-bottom: -6px;
	display: flex;
	align-items: center;
	margin-top: 3px;
	min-height: 60px;
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
}

.skillsetheadingfont {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 12px;
}

.skillsetheadingcontent {
	color: var(--ld, #5bc4bf);
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	margin: 0px 3px;
}

.backButtonHolder {
	border-radius: 8px 8px 0px 0px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
	height: 41px;
	display: flex;
	margin-bottom: 18px;
}

.TableEmployeeSkillheading {
	width: 89%;
	display: flex;
	justify-content: space-around;
}

.TableEmployeeSkillheadingdiv {
	color: var(--PeopleSol-Dark-Grey, #555);
	text-align: left;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 17.32px;
	padding: 11px 11px;
	min-width: 220px;
}

.TableEmployeeSkillbody {
	width: 89%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.TableEmployeeSkillbody>div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 140px;
}

.TableEmployeeSkillSetDesign {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: 741px;
	overflow: auto;
	justify-content: flex-start;
}

.TableEmployeeSkillSetDesigndiv {
	width: 95%;
	height: 38px;
	border-radius: 6px;
	background: #fbfbfb;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 3px 0px;
}

.TableEmployeeSkillSetcontentDesign {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.TableEmployeeSkillSetcontentDesign>div {
	width: 95%;
	height: 48px;
	border-radius: 6px;
	background: #fff;
	filter: drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04));
	display: flex;
	/* flex-direction: column; */
	justify-content: space-between;
	align-items: center;
	margin: 10px 0px;
}

.competencyNameStyle {
	border-radius: 4px;
	background: var(--ATS-2, #eaf5ff);
	color: var(--ATS, #77a2c7);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	padding: 3px 14px;
	width: max-content;
	/* line-height: 17.32px; */
	/* 123.712% */
}

.skillsLevelStyle {
	border-radius: 4px;
	background: var(--PMS---Pastel, #fff2f0);
	color: var(--PMS, #fb7158);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	padding: 3px 14px;
	width: max-content;
}

.documentNameStyle {
	color: var(--PeopleSOl-Primery, #1296b0);
	text-align: left;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 133.228% */
}

.employeeNameStyle {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	/* width: 177px !important; */
	text-align: left;
	/* 142.857% */
}

.lastUpdatedOnStyle {
	color: var(--PeopleSol-Dark-Grey, #555);
	text-align: left;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
}

.skillbuttondesign {
	color: var(--White, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 144.33% */
	border-radius: 4px;
	background: var(--PeopleSOl-Primery, #1296b0);
	padding: 6px 6px;
	margin: 0px 13px 0px 0px;
	max-width: 122px;
	cursor: pointer;
}

.circleOrganisationPopup {
	width: 75px;
	margin: 0px 33px 20px 33px;
}

.mainOrganisationPopup {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 725px;
	padding: 10px;
}

.popupCss {
	justify-content: flex-start;
}

.organisationPopupcontent {
	display: flex;
	flex-wrap: wrap;
}

.OrganisationPopupHeading {
	color: var(--ATS, #77a2c7);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 123.712% */
	border-radius: 4px;
	background: var(--ATS-2, #eaf5ff);
	padding: 6px;
	margin: 10px 0px 20px 33px;
}

.circleOrganisationname {
	color: var(--PeopleSol-Dark-Grey, #555);
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 19.515px;
	/* 177.405% */
}

.courseDetailcolumn {
	margin-right: 22px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 134px;
}

.totalDurationAlign {
	display: flex;
	align-items: center;
}

.totaldurationfont {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 13px;
	/* 185.714% */
	margin-bottom: 2px;
}

.mandatoryfont {
	border-radius: 3.786px;
	background: var(--Tax, #8a88ed);
	color: var(--White, #fff);
	text-align: center;
	font-family: Poppins;
	font-size: 8.5px;
	font-style: normal;
	font-weight: 400;
	line-height: 12.854px;
	padding: 2px 5px;
	align-self: flex-start;
	/* margin: 9px 16px 0px 0px; */
}

.courseBlockHolderdiv {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 10px;
}

.completionFont {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 13px;
	/* 185.714% */
	letter-spacing: -0.07px;
}

.timeFont {
	color: var(--PeopleSOl-Primery, #1296b0);
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 13px;
	/* 138.298% */
	letter-spacing: -0.094px;
}

/* .tabActiveLD {
	border-radius: 5px;
	background: var(--pastel-7, #5bc4bf);
	box-shadow: 0px 4.58333px 12.49999px 0px rgba(7, 117, 112, 0.15) inset;
	padding: 6px 19px;
	color: var(--White, #fff);
} */
.tabActiveLD {
	display: flex;
	border-radius: 5px;
	background: var(--pastel-7, #5bc4bf);
	box-shadow: 0px 4.58333px 12.49999px 0px rgba(7, 117, 112, 0.15) inset;
	/* padding: 6px 19px; */
	color: var(--White, #fff);
	width: 124px !important;
	height: 28px;
	justify-content: center;
	align-items: center;
}

.timeFontChapterActive {
	color: var(--White, #FFF);
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 13px; */
	/* 130% */
}

.timeFontChapter {
	color: #555;
	font-family: Poppins;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 13px; */
	/* 130% */
}

.IconSizeLdForm {
	height: 13px;
}

.progressBarCompleted {
	position: absolute;
	left: 0;
	height: 100%;
	max-width: 100%;
	border-radius: 4px;
	/* background-color: #1296B0; */

}

.dropdownBackgroundLD {
	background-color: rgb(239, 250, 251);
}

.dropdownHolderBackgroundLD {
	background-color: rgb(228 247 249);
}

.tabContainerld .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	padding: 0px 8px !important;
	min-height: 39px !important;
}

.LDresultsDetails {
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
}

.LDresultsDetailsFont {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.3px;
}

.DetailsAssesmentParentDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	margin: 25px 0px 20px 0px;
}

.DetailsDashboardLD {
	width: 96%;
	background-color: #FFF;
	filter: drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04));
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 20px 0px;


}

.DetailsDashboardDiv {
	width: 96%;
	display: flex;
	align-items: center;
	justify-content: space-between;

}

.DashboardQuestionDiv {
	width: 208px;
	height: 54px;
	border-radius: 8px;
	background: var(--tax---Pastel, #F0F0FF);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	margin: 0px 20px 0px 0px;

}

.DashboardQuestionDivKey {
	color: var(--Tax, #8A88ED);
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.24px;
	width: 84px;

}

.DashboardQuestionDivValue {
	color: var(--Tax, #8A88ED);
	font-family: Poppins;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 57.732% */
	letter-spacing: 0.6px;
}

.DetailsAssesmentlabelFont {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 10.5px;
	font-style: normal;
	font-weight: 400;
	line-height: 16.46px;
	/* 164.601% */
	letter-spacing: -0.1px;
}

.DetailsDashboardDivfont1 {
	color: var(--PeopleSOl-Primery, #1296B0);
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 17.32px;
	/* 86.598% */
	letter-spacing: 0.4px;
	margin: 0px 0px 7px 0px;
}

.DetailsDashboardDivfont2 {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 17.32px;
	/* 123.712% */
	letter-spacing: 0.28px;
}

.AssesmentLDtable {
	width: 100%;
}

.AssesmentLDtableHead {
	border-radius: 4px;
	background: #FBFBFB;
	height: 40px;
}

.AssesmentLDtableHead>tr th {
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 12.5px;
	font-style: normal;
	font-weight: 400;
	line-height: 17.32px;
	text-align: left;

	/* 144.33% */
}

.AssesmentLDtable th {
	padding: 10px;
}

.AssesmentLDtable tr {
	border-bottom: 1px solid #E6E6E6;
}

.AssesmentLDtable td {
	padding: 10px;
}

.answerColumn {
	width: 127px;
}

.questionColumn {
	width: 953.88px;
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	/* 100% */
}

.slnoColumn {
	width: 74px;
	color: var(--PeopleSol-Dark-Grey, #555);
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	/* 100% */

}

.buttonpaly {
	cursor: pointer;
}

.skillsetheadingdiv {
	display: flex;

}

.SkillsLDtable {
	width: 100%;
	margin: 20px 0px 0px 0px;
	border-collapse: separate;
	border-spacing: 0 10px;
	/* Adjust the second value for vertical spacing */

}

.SkillsLDtableHead {
	border-radius: 6px;
	background: #FBFBFB;
	padding: 5px;
}

.SkillsLDtable tbody>tr {
	background-color: #FFF;
	filter: drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04));
}

.SkillsLDtable tbody td {
	padding: 11px 11px;
}

.blurCertificate {

	backdrop-filter: blur(10px);
}

.absoluteFullScreen {
	position: absolute;
	right: 17px;
	bottom: 5px;
	z-index: 23;
	width: 30.9px;
	cursor: pointer;
}

.absoluteFullScreenFile {
	position: absolute;
	right: 16px;
	bottom: 6px;
	z-index: 23;
	width: 47%;
	cursor: pointer;
}


.absoluteFullScreenDiv {
	width: 59px;
	height: 39px;
	/* background-color: black; */
	position: absolute;
	bottom: 18px;
	right: 18px;
}

/* .fade-out-divLD {
	opacity: 1;
	transition: opacity 1s ease-in-out;
} */



.fade-out-divLD {
	opacity: 0 !important;
	transition: opacity 0.1s ease-in-out !important;
}

#fullScreenIconVideoLD:hover .fade-out-divLD {
	opacity: 1 !important;
	transition: opacity 0.1s ease-in-out !important;
	/* Adjust the duration and easing as needed */
}

/* .fade-out-divLD.fade-outLD {
	opacity: 0;
} */

.absoluteFullScreenDivFile {
	width: 59px;
	height: 39px;
	/* background-color: black; */
	position: absolute;
	bottom: 48px;
	right: 108px;
}

.absoluteFullScreenDivFull {
	width: 59px;
	height: 40px;
	/* background-color: black; */
	position: absolute;
	bottom: 0px;
	right: 0px;
	z-index: 20;

}

.absoluteFullScreenDivFullFile {
	width: 5%;
	height: 40px;
	/* background-color: black; */
	position: absolute;
	bottom: 25.5px;
	right: 147px;
	z-index: 20;

}

#fullScreenIconVideoLD video::-webkit-media-controls-fullscreen-button {
	display: none !important;
}

.primaryLDButtonDisabled {
	cursor: default;
	background-color: #E6E6E6;
	color: #B8B8B8;
	/* box-shadow: 0px 3.52262px 7.04523px rgb(18 150 176 / 20%),
		inset 0px 3.52262px 8.80654px rgb(0 0 0 / 15%); */
}

.videoCoverImg {
	position: absolute;

}

.lightVideoStyle {
	height: 100%;
	width: fit-content;
	max-width: 100%;

}

@media screen and (max-width:1567px) {

	.lightVideoStyle {
		max-height: 100%;
		height: fit-content;
		width: 100%;
	}
}

.certificatePopup {
	display: none;
}

.certificatePopupBox {
	min-height: 230px;
}

.Redirectbutton {
	color: #1296B0;
	width: fit-content;
	padding: 4px 16px;
	background-color: #D8F0F2;
	border-radius: 3px;
}

.Redirectbutton:hover {
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.RedirectbuttonCover {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12px 0px;
}

.LDvideoPopup {
	position: absolute;
	z-index: 100;
	width: calc(100% - 36px);
	height: 93%;
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(10px);
	background-color: rgba(0, 0, 0, 0.3);
}

.RadiotoCheckboxLD input[type="radio"] {
	display: none;
}

/* Style the label to look like a checkbox */
.RadiotoCheckboxLD input[type="radio"]+label {
	display: inline-block;
	margin: 3px 5px;
	padding-left: 30px;
	position: relative;
	cursor: pointer;
	user-select: none;
}

/* Box for the fake checkbox */
.RadiotoCheckboxLD input[type="radio"]+label:before {
	content: "";
	position: absolute;
	left: -6px;
	top: 0;
	border: 0.2px solid #000;
	accent-color: rgb(18, 150, 176);
	width: 13.2px;
	height: 14px;
	background: rgb(255, 255, 255);
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 2px;
	border-radius: 3px;
}

/* Checkmark to appear when checked */
.RadiotoCheckboxLD input[type="radio"]:checked+label:after {
	border: 1px solid transparent;
	content: '';
	display: block;
	background-image: url("../../../Assets/Ticks.svg");
	background-size: contain;
	position: absolute;
	width: 13.2px;
	height: 14px;
	left: -6px;
	top: 0;
	background-color: rgb(18, 150, 176);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 3px;

}

.RadiotoCheckboxLD input[type="checkbox"] {
	display: none;
}

/* Style the label to look like a checkbox */
.RadiotoCheckboxLD input[type="checkbox"]+label {
	display: inline-block;
	margin: 3px 5px;
	padding-left: 30px;
	position: relative;
	cursor: pointer;
	user-select: none;
}

.RadiotoCheckboxLDPMS input[type="checkbox"]+label {
	display: inline-block;
	margin: 3px 5px;
	padding-left: 0px !important;
	position: relative;
	cursor: pointer;
	user-select: none;
}

/* Box for the fake checkbox */
.RadiotoCheckboxLD input[type="checkbox"]+label:before {
	content: "";
	position: absolute;
	left: -6px;
	top: 0;
	border: 0.2px solid #000;
	accent-color: rgb(18, 150, 176);
	width: 13.2px;
	height: 14px;
	background: rgb(255, 255, 255);
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 2px;
	border-radius: 3px;
}

/* Checkmark to appear when checked */
.RadiotoCheckboxLD input[type="checkbox"]:checked+label:after {
	border: 1px solid transparent;
	content: '';
	display: block;
	background-image: url("../../../Assets/Ticks.svg");
	background-size: contain;
	position: absolute;
	width: 13.2px;
	height: 14px;
	left: -6px;
	top: 0;
	background-color: rgb(18, 150, 176);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 3px;

}