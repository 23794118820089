.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  max-width: 450px;
  gap: 0rem;
  margin: 50px 0;
}

.avatar-box {
  width: 18rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  justify-content: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  filter: brightness(50%);
  cursor: pointer;
  border: 1.5px solid transparent;
}

.avatar-img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.name-fields {
  display: flex;
  gap: 0.5rem;
}

.password-container {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.password-icons {
  position: absolute;
  right: 0;
  top: 15px;
}

.loginError {
  font-size: 11px;
  display: flex;
  align-items: center;
  position: absolute;
  margin: -2px 11px;
  white-space: nowrap;
  bottom: 2px;
  gap: 2px;
  color: red;
  font-family: "Poppins";
}

.avatar:hover,
.avatar-focus {
  filter: brightness(100%);
  border: 1.5px solid var(--primary);
}
.loginText {
  color: var(--primary);
  align-self: flex-start;
  font-weight: bold;
  margin: 0 10px;
  font-size: 25px;
}
.ForgotText {
  color: var(--primary);
  align-self: flex-end;
  margin: 0 10px;
  font-size: 15px;
}
.loginInput {
  box-sizing: border-box;
  margin: 10px 0;
  padding-left: 10px;
  display: flex;
  font-size: 18px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #1296b0;
  border-radius: 6.20606px;
  font-weight: 400;
}
.loginInput::placeholder {
  font-size: 18px;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b9b9b9ed;
  opacity: 0.8;
  font-weight: 400;
}
.loginInput:focus {
  outline: none !important;
  border: 1px solid var(--secondary);
}
.visibilityIcon {
  align-self: center;
  width: 25px;
  height: 25px;
}
.loginButton {
  background-color: var(--primary);
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border: 0.775758px solid #1296b0;
  border-radius: 6.20606px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  position: relative;
  font-size: 22px;
}
.loginButtonArrow {
  position: absolute;
  display: none;
}
.loginButton:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.loginButton:hover > .loginButtonArrow {
  display: block;
  right: 1rem;
}
.forgotPwd{
  width: 100%;
  text-align: end;
  margin-top: 10px;
  color: #1296b0;
  font-size: 13px;
}
.forgotPwd span{
  cursor: pointer;
}