.printdiv {
  width: 100% !important;
  display: flex !important;
  align-items: start !important;
  margin: 10px 0px;
}

.printFirstp {
  margin-left: 20px !important;
  width: 274px !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 430 !important;
  line-height: 30px !important;
  /* margin-left: 18px !important; */
  color: #555;
}

.printRequestID {
  width: 190px !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 430 !important;
  line-height: 30px !important;
  /* margin-left: 18px !important; */
  color: #194f78;
}

.printFirstp1 {
  min-width: 114px !important;
}

.printSecondp {
  /* width: 160px !important; */
  width: 237px !important;
  font-family: Poppins !important;
  margin-left: 20px !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  color: #555;
}

.printNamep {
  width: 190px !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  color: #194f78;
}

.printSecondp1 {
  margin-left: 20px !important;
  width: 190px !important;

  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 25px !important;
  color: black;
}

.printPhoto {
  width: 150px;
  height: 150px;
  background-color: black;
  border-radius: 16px;
}

@media print {
  @page {
    /* size: landscape; */
    /* Set the page orientation to landscape */
    margin: none;
    /* Set margins as needed, e.g., 1cm */
  }

  .bady {
    transform-origin: top left;
    /* Set the origin of transformation */
    transform: scale(0.3);
    /* Scale down to half size */
    width: 860px;
    /* Compensate for the scaling by increasing the width */
    height: 200px;
    /* Compensate for the scaling by increasing the height */
    margin: 0;
  }

  .printPhoto {
    display: block;
    width: 150px;
    height: 150px;
    background-color: black;
    border-radius: 16px;
  }
}

.printBottomline {
  background-color: #154f78;
  height: 25px;
}

.printdiv1cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 16px;
  width: 90% !important;
  border-radius: 0px 0px 6px 6px;
  background: #fff;
  padding: 14px;
}

.calendarPopup {
  width: 900px;
  border-radius: 8px;
  background: #faf9f8;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
}

.calendarPopupHeading {
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  /* box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05); */
  padding: 10px;
}

.calendarPopupDay {
  color: var(--PeopleSol-Primery, #1296b0);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.26px;
}

.calendarPopupDate {
  color: var(--PeopleSol-Dark-Grey, #555);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 146.667% */
  letter-spacing: 0.3px;
}

.tableHeading {
  border-radius: 6px 6px 0px 0px;
  background: var(--Pastel, #d8f0f2);
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 14px;
}

.tableHeading>div {
  color: var(--PeopleSol-Primery, #1296b0);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.297px;
  /* 152.474% */
  letter-spacing: 0.24px;
}

.calendarpopupfont {
  color: var(--Text-grey, #555);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.297px;
  /* 152.474% */
  letter-spacing: 0.24px;
}

.printPhotoDiv {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media print {
  body * {
    visibility: hidden;
  }

  .print-version,
  .print-version * {
    visibility: visible;
  }

  .print-version {
    position: absolute;
    left: 0;
    top: 0;
  }

  .printBottomlineprint {
    border-bottom: 25px solid #154f78;
    width: 100%;
    /* position: absolute;
        bottom: 25px; */
    margin-top: 30px;

    /* background-color: #154f78 !important;
        height: 25px !important; */
  }

  .printBorder {
    position: relative;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px 0px 0px 0px;
  }
}

.printQRcodeDiv {
  margin-right: 30px;
  margin-top: 12px;
}