.headerCountContainer{
    display: flex;
    /* width: 33rem; */
    align-items: center;
}
.headerContentHolder{
    justify-content: space-between;
    padding: 12px 16px !important;
}
.headerLastContainer{
    display: flex;
    font-size: 20px;
}
/* .headerLastContainer > p{
    font-size: 20px;
} */
.headerLastContainer > label{
    font-family: 'Poppins';
    font-weight: 500;
    color: #1296B0;
    text-decoration: underline var(--primary) 2px;
    margin-left: 16px;
    
}

.timesheetVerticalLine{
    width: 1px;
    background-color: var(--grey);
    height: 54px;
    margin: 0 15px;
}
/* .greenBox{
    background-color: greenyellow;
} */
.timesheetFooter{
    justify-content: flex-end;
} 

.timesheetFooter > label{
    color: #555555;
    font-size: 15px;
}