.returnIconHolder {
  position: absolute;
  top: 6px;
  right: 6px;
}
.innerContLeft {
  background-color: #e7f8ec;
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
}
.innerContRight {
  background-color: white;
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  contain: content;
  overflow: auto;
}
.feedbackPermissionHolder {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}
.feedbackPermissionText {
  color: var(--primary);
  font-size: 3rem;
  font-weight: 500;
}
.formIconHolder {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.formDetailsHolder {
  height: 100%;
  width: 50%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.formGreetingMessage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 11rem;
  margin-left: 2rem;
}
.questionHolder {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 24px;
}
.sideFeedbackDetails {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.buttonHolder {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 3rem;
  position: absolute;
  bottom: 1rem;
}
.feedbackSubmitButton {
  color: white;
  width: 8rem;
  margin-right: 2rem;
}
.feedbackFormHeader {
  display: flex;
  height: 40%;
  width: 100%;
}
.feedbackFormFooter {
  /* height: 60%; */
  height: 33.5rem;
  position: relative;
}
.formHolder {
  height: calc(100% - 4rem);
  contain: content;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
}
